/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import { Form, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './Lista.css';


let entidades_tipos = [
    {id: 1, name: "Filiados"},
    {id: 0, name: "Não filiados"},
    {id: 2, name: "FIliados e não filiados"}
];


let atletas_tipos =  [
    {id: 1, name: "Visuais"},
    {id: 2, name: "Fisicos"},
    {id: 4, name: "Intelectuais"},
    {id: 0, name: "Visuais, fisicos e intelectuais"}
  ];


export default function Lista({...props}) {
    const component_name = "admin/etapas_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: ""});
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const {EventoId} = props;

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


     //#region Init
     const Init = () => {
        _HandlerSearchChange();
     }
    //#endregion Init



    //#region Loaders
    
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        _HandlerSearchChange(event);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Eventos/Etapas/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", {...search, evento_id: EventoId}, user.token))
            .then(async(data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);


            Promise.resolve(new Request().Run("api/Eventos/Etapas/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST",  {...search, evento_id: EventoId}, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }






    const _HandleEdit = (e, id) => {
        props.onEdit(id?id:"");
    }


    const _HandleAddEtapa = (id) => {
        props.onEdit(id?id:"");
    }


    const _HandleProvas = (item) => {
        navigate("/PrintService/Provas/"+item.etapa_id);
    }



    const _HandleReport = (e, id) => {
        navigate("/Relatorios/Evento/"+id);
    }


    const _HandlePlanilha = (item) => {
        navigate("/PrintService/Planilha/"+item.etapa_id);
    }
    //#endregion Handlers


    return (
        <div className="_card Etapa">
            <div className="card-header _justify-content-between _d-flex _align-items-center">
                <h4 className="card-title"> </h4>

                <div className="row"> 
                    <div className="col-xl-12"> 
                        <div className={'align-right ' + (user.nivel>1?'hide':'')}>
                            <button type="button" className="btn btn-primary" onClick={() => _HandleAddEtapa("")}>
                                <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                            </button>
                        </div>

                        <div className="align-right mr-10">
                            <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                            </button>
                        </div>

                        <div className="search-box align-right mr-10">
                            <div className="position-relative">
                                <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                <i className="bx bx-search search-icon"></i>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div className="_card-body pb-0">
                <div className="table-responsive">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={_fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Carregando...</b>
                            </p>
                        }
                        style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                        endMessage={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Não há mais dados</b>
                            </p>
                        }>    

                        <table className="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th>Nome da Etapa</th>
                                    <th>Modalidade</th>
                                    <th>Entidades</th>
                                    <th>Atletas</th>
                                    <th>V Afiliado</th>
                                    <th>V Não Afiliado</th>
                                    <th>Provas</th>
                                    <th style={{width:'125px'}} className={(user.nivel>1?'_hide':'')}></th>
                                </tr>
                            </thead>


                            <tbody>
                            {list.map((item, i) => (
                                <tr key={'tr' + i} className={'pointer '}>
                                    <td>{item.nome}</td>
                                    <td>{item.modalidade}</td>
                                    <td>{entidades_tipos.find(i => i.id === item.entidades_tipos).name}</td>
                                    <td>{atletas_tipos.find(i => i.id === item.atletas_tipos).name}</td>
                                    <td>{Currency(item.valor_afiliado)}</td>
                                    <td>{Currency(item.valor_nafiliado)}</td>
                                    <td>{item.provas}</td>
                                    <td>
                                        <center>
                                                <ReactTooltip key={"tooltip_a"+i} anchorId={"td_ed"+i} variant="info" place="top" content={'Editar'} />
                                                <i id={"td_ed"+i} className={'fa fa-edit ' + (user.nivel>1?'hide':'')} onClick={(e) => _HandleEdit( e, item.etapa_id)}></i>
                                                &nbsp;&nbsp;
                                                <ReactTooltip key={"tooltip_a"+i} anchorId={"td_pr"+i} variant="info" place="top" content={'Provas'} />
                                                <i id={"td_pr"+i} className={'fas fa-user'} onClick={() => _HandleProvas(item)}></i>
                                                &nbsp;&nbsp;
                                                <ReactTooltip key={"tooltip_a"+i} anchorId={"td_ic"+i} variant="info" place="top" content={'Planilhas'} />
                                                <i id={"td_ic"+i} className={'far fa-file-excel'} onClick={() => _HandlePlanilha(item)}></i>
                                        </center>
                                    </td>
                                </tr>
                            ))}
                            
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}