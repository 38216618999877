﻿export const Cache = {
    Set: (key, value) => {
        localStorage.setItem(key, value);
    },


    Get: (key, ephemeral) => {
        if(ephemeral){
            let result = localStorage.getItem(key);
            localStorage.removeItem(key);
            return result;
        } else {
            return localStorage.getItem(key);
        }
    },


    JSet: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },


    JGet: (key, ephemeral) => {
        if(ephemeral){
            let result = localStorage.getItem(key);
            localStorage.removeItem(key);
            return JSON.parse(result);
        } else {
            return JSON.parse(localStorage.getItem(key));
        }
    },

    Remove: (key) => {
        localStorage.removeItem(key);
    },

    Delete: (key) => Cache.Remove,

    Clear: ()  =>  { localStorage.clear() }
  
}