/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import './Info.css';


export default function FichasPendentes() {
    const component_name = "admin/info_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState({});


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    const navigate = useNavigate();


     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        LoadInfo();
    }
    //#endregion Init


    //#region Loaders
    const LoadInfo = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Home/Resume", "POST", "", user.token, 0))
        .then(async(data) => {
            setPageLoading(false);
            if(data.toString() !== "error") {
                setInfo(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD01)", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD002)", "error");
        });
    }
    //#endregion Loaders


    return (
        <div className="card-body">
            <div className="row">
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                            <i className="mdi mdi-star-check-outline"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-16 mb-1">{info?.entidades_ativas}</h5>
                                    <p className="mb-0 text-truncate text-muted">Entidades Ativas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                            <i className="mdi mdi-star-off-outline"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-16 mb-1">{info?.entidades_inativas}</h5>
                                    <p className="mb-0 text-truncate text-muted">Entidades Inativas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                            <i className="uil uil-users-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-16 mb-1">{info?.atletas_ativos}</h5>
                                    <p className="mb-0 text-truncate text-muted">Atletas Ativos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar">
                                        <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                            <i className="uil uil-user-exclamation"></i>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-16 mb-1">{info?.atletas_inativos}</h5>
                                    <p className="mb-0 text-truncate text-muted">Atletas Inativos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
    )
}


