/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { CacheDB } from '../../../scripts/CacheDB';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { cnpj as CnpjValidate} from 'cpf-cnpj-validator'; 
import ReactGA from 'react-ga';

import Lista from './Lista';


let current_element;
let cliente_auto_fill = false;


export function Cadastro() {
    const component_name = "admin/entidade_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [entidadeId, setEntidadeId] = useState(undefined);
    const [isFormValidaded, setIsFormValidaded] = useState(false);

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { Id } = useParams();

    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        let Tipo = await Hub.Get("ENTIDADE_TIPO_FILTER");
        await CacheDB.Set("entidades_"+Tipo+"_keep", 1);
    }
    //#endregion Init


    //#region Loaders
    const LoadUfs = (uf = 26) => {
        Promise.resolve(new Request().Run("/assets/json/estados.json", "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("uf_codigo", data);
            await _formUserRef.current.Value("uf_codigo", uf);
            LoadCidades(uf)

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadCidades = (uf_codigo, city = "") => {
        if(uf_codigo && uf_codigo !== "") {
            Promise.resolve(new Request().Run("api/Entidades/Cidades?uf_codigo=" + uf_codigo, "GET", "", user.token, 0*24*60*1000))
            .then(async(data) => {
                setPageLoading(false);
                await _formUserRef.current.Select("cidade_codigo", data);

                if(city !== "") {
                    city = city.toLocaleUpperCase();
                    let _id = data.find(c => c.name === city).id;
                    await _formUserRef.current.Value("cidade_codigo", _id);
                }

                if(data.toString() !== "error") {
                } else {
                    window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
                }
            }).catch(() => {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            });
        }
    }

    //#endregion Loaders


    //#region Form Handlers
    const login = async (value) => {
        if(!cliente_auto_fill) return;
        current_element = "#login";
        Promise.resolve(new Request().Run("api/Entidades/CheckLogin", "POST", {login: value, entidade_id: Id}, user.token, 0)).then(async(data) => {
            current_element = "";
            if(data.toString() === "exists") {
                window.swal("Alerta", "Esse login já está associado à uma entidade existente! ", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ENTCAD001)", "error");
        });
    }


    const cnpj = async (value) => {
        if(!cliente_auto_fill) return;
        setIsFormValidaded(false);
    }


    const blur_cnpj = async (value) => {
        if(!CnpjValidate.isValid(value) && value !== "11.111.111/1111-11") {
            window.swal("Alerta", "CNPJ inválido", "error");
            return;
        }

        current_element = "#cnpj";
        Promise.resolve(new Request().Run("api/Entidades/CheckCNPJ", "POST", {cnpj: value, entidade_id: Id}, user.token, 0)).then(async(data) => {
            current_element = "";
            if(data.toString() === "exists") {
                window.swal("Alerta", "Esse CNPJjá está associado à uma entidade existente! ", "error");
            } else {
                setIsFormValidaded(true);
            }

        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ENTCAD002)", "error");
        });
    }
        

    const uf_codigo = value => {
        LoadCidades(value);
    }

    
    const cep = async value => {
        //console.log(cliente_auto_fill, value);
        if(!cliente_auto_fill) return;

        if(value.length === 9 ) {
            Promise.resolve(new Request().Run("https://brasilapi.com.br/api/cep/v1/" + value.replace('-',''), "GET", "", "", 0)).then(async(data) => {
                if(data.toString() !== "error") {
                    await _formUserRef.current.Value("endereco", data.street);
                    await _formUserRef.current.Value("bairro", data.neighborhood);
                    let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                    let _uf = _uf_options.find(u => u.sigla === data.state).id;
                    await _formUserRef.current.Value("uf_codigo", _uf);
                    LoadCidades(_uf, data.city);
                }
            }).catch(() => {
            
            });
        }
    }

    
    const OnFormLoading = async() => {
        cliente_auto_fill = false;
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
        
 
        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Id) {
                setEntidadeId(Id);
                let unidade_data = await new Request().Run("api/Entidades/Load?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.entidade_id !== null) {
                    await _formUserRef.current.Load(unidade_data);
                    ValidadeCNPJ(unidade_data.cnpj)
                }
            }

            setTimeout(()=> {
                LoadUfs();
            }, 100);

            setTimeout(()=> {
                cliente_auto_fill = true;
            }, 500);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
            let _senha = _formUserRef.current.GetValue("senha");
            let _resenha =  _formUserRef.current.GetValue("resenha");
            
            if(_senha !== "" && _senha !== _resenha ) {
                window.swal("Alerta", "Senha e repetição da senha não conferem", "error");
                return;
            }

             setPageLoading(true);
            //_formUserRef.current.Test("","");
            let result = await _formUserRef.current.Post("api/Entidades/Save", {entidade_id: Id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Entidade já registrada", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            setEntidadeId(result)
            //navigate("/Entidades");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }


    const _HandlerAlert = (alerts) => {
        setAlerts(alerts);
    }


    const _HandleBack = async() => {
        let tipo = await Hub.Get("ENTIDADE_TIPO_FILTER");
        console.log(tipo);
        if(tipo) {
            navigate("/Entidades/"+tipo);
        } else {
            navigate("/Entidades");
        }
    }
    //#endregion Handlers
     

    //#region Funtions
    const ValidadeCNPJ = (value) => {
        if(Id) {
            if(!CnpjValidate.isValid(value) && value !== "11.111.111/1111-11") {
                setIsFormValidaded(false);
                window.swal("Alerta", "CNPJ inválido", "error");
            } else {
                setIsFormValidaded(true);
            }
        }
    }
    //#endregion Functions

    return(
        <div className="main-content Entidades">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Entidade {Array.isArray(alerts) && alerts.length > 0 && (<span style={{color:'red'}}> &nbsp; (Documentos faltando: {alerts.join(', ')})</span>)}</h4>
                                    <div className="align-right mr-10">
                                        <button type="button" className="btn btn-outline-primary mr-10" onClick={_HandleBack}>
                                            <i className="mdi mdi-arrow-left"></i> &nbsp;Lista &nbsp;
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('entidade', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'unidade'} url={'/assets/forms/entidade.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit" disabled={!isFormValidaded}>Salvar informações</button>
                 
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>

                                    { entidadeId && (
                                        <Lista EntidadeId={entidadeId} OnAlert={_HandlerAlert}/>
                                    )}
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>

        </div>
    )
}