/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Fichas.css';



let provasData = [];
let provasTipo = "";


export function Fichas() {
    const component_name = "admin/print_fichas";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [entidade, setEntidade] = useState({});
    const [evento, setEvento] = useState({});
    const [etapas, setEtapas] = useState({});
    const [atletas, setAtletas] = useState([]);
    const [fichas, setFichas] = useState([]);
    const [provas, setProvas] = useState([]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { EntidadeId, EtapaId } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        await LoadProvas();
        await LoadEtapa();
    }
    //#endregion Init



    //#region Loaders
    const LoadProvas = async() => {
        let _provas = await Req("/assets/json/provas.json", "GET", "", "", 0*24*60*1000);
        if(_provas.toString() !== "error") {
            provasData = _provas;
            //console.log(provasData);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
        }

        return true;
    }


    const LoadEtapa = async() => {
        setPageLoading(true);
        await Req("api/Reports/Fichas", "POST", {entidade_id: EntidadeId, etapa_id: EtapaId}, user.token, 0).then(data => { 
            setPageLoading(false);

            setEvento(data.evento);
            setEntidade(data.entidade);
            setFichas(data.fichas);

            let arr = [];
            if(data?.evento?.modalidade?.toString().toLowerCase().indexOf('atletismo') > -1) arr = provasData['atletismo'];
            if(data?.evento?.modalidade?.toString().toLowerCase().indexOf('natação') > -1) arr = provasData['natacao'];
            //console.log(arr);
            if(arr.length > 0) setProvas(arr);

            setTimeout(() => {
                //window.print();
            }, 1000);
        }).catch(() => { 
            setPageLoading(false);
        });
    }
    
    //#endregion Loaders





    return (
        <>
            <div id="PrintFicha" align="center" style={{width:'100%'}}>
                <br />
                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <tbody>
                        <tr>
                            <td align="center" style={{fontFamily: 'Verdana, Arial', fontSize:'10pt', padding:'15px 8px 8px 8px'}}>
                                <img src="/assets/images/logo_print.jpg" height="60px" align="left" style={{margin: '0px 20px 0px 3px'}} /> 
                                <b>{evento?.nome}
                                <br/>{evento?.cidade}/{evento?.uf} - De {moment(evento?.inicio).format('DD/MM/YYYY')} a {moment(evento?.fim).format('DD/MM/YYYY')} </b><br />
                                <h5>{evento?.local}</h5> 
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <center>
                    <h5>Ficha de Inscrição {evento?.modalidade}</h5>
                    <h6>{evento?.etapa}</h6>
                </center>

                <table border="2" cellSpacing="0" style={{margin: '5px', width:'820px'}}>
                    <tbody>
                        <tr>
                            <td style={{width:'100px'}}>Entidade</td>
                            <td>{entidade?.abrev} - {entidade?.nome}</td>
                        </tr>
                        <tr>
                            <td>CNPJ</td>
                            <td>{entidade?.cnpj}</td>
                        </tr>
                        <tr>
                            <td>Cidade</td>
                            <td>{entidade?.cidade}-{entidade?.uf}</td>
                        </tr>
                        <tr>
                            <td>Endereço</td>
                            <td>{entidade?.endereco} {entidade?.numero}  / CEP: {entidade?.cep}</td>
                        </tr>
                        <tr>
                            <td>Telefone</td>
                            <td>{entidade?.telefone1}</td>
                        </tr>
                        <tr>
                            <td>Emails</td>
                            <td>{entidade?.emails}</td>
                        </tr>
                    </tbody>
                </table>

                {Array.isArray(fichas) && fichas?.length > 0 && fichas?.map((item, i) => (
                    <React.Fragment key={'ficha'+i}>
                        { i === 0 && (<>
                        <table border="2" cellspacing="0" style={{margin: '5px', width:'820px'}} className={item.tecnico===''?'_hide':''}>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <b>Time {i===0?"A":"B"}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'100px'}}>Técnico</td>
                                    <td>{item.tecnico}</td>
                                </tr>
                                <tr>
                                    <td>Auxiliar</td>
                                    <td>{item.auxiliar}</td>
                                </tr>
                                <tr>
                                    <td>Acompanhante</td>
                                    <td>{item.acompanhante}</td>
                                </tr>
                            </tbody>
                        </table>

                        
                        <table border="2" cellSpacing="0" style={{margin: '5px', width:'820px'}} className={item.tecnico===''?'_hide':''}>
                            <thead>
                                <tr>
                                    <th>Num</th>
                                    <th>Nome</th>
                                    <th>CPF</th>
                                    <th>RG</th>
                                    <th>Nascimento</th>
                                </tr>
                            </thead>

                            <tbody>
                                {Array.isArray(item.atletas) && item.atletas.length > 0 && item.atletas.map((atleta, j) => (
                                    <React.Fragment key={'atleta'+i+'_'+j}>
                                         <tr>
                                            <td>
                                                {atleta.numero}
                                            </td>
                                            <td>
                                                {atleta.atleta}
                                            </td>
                                            <td>
                                                {atleta.cpf}
                                            </td>
                                            <td>
                                                {atleta.rg}
                                            </td>
                                            <td>
                                                {moment(atleta.nascimento).format('DD/MM/YYYY')}
                                            </td>
                                        </tr>
                                        <tr className={(Array.isArray(atleta.provas) && atleta.provas.length > 0)?'':'hide'}>
                                            <td colSpan={5}>
                                                {Array.isArray(atleta.provas) && atleta.provas.length > 0 && atleta.provas.map((prova, k) => (
                                                   <span key={'peova'+i+'_'+j+'_'+k}><b>{provas.find(p => p.prova_id === prova.prova_id)?.nome}</b> ({prova?.niveis}) &nbsp;</span>
                                                ))}
                                            </td>
                                        </tr>
                                    </React.Fragment>    
                                ))}
                            </tbody>
                        </table>

                        <hr />
                        </>)}
                    </React.Fragment>
                    ))
                }
            </div>
        </>
    )
}