/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './index.css';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


let current_element;
let search_cliente;
let search_element;


const atleta_status = [
    "Pendente",
    "Regularizado",
    "Em tranferência",
    "Documentos pendentes"
];


const atleta_condicao =  [
    {id:0, "name" :"Não informado"}, 
    {id:1, "name" :"Deficiente visual"}, 
    {id:2, "name" :"Deficiente físico"},
    {id:4, "name" :"Deficiente intelectual"},
    {id:3, "name" :"Staff"}
];




export default function Lista() {
    const component_name = "admin/atletas_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [entidades, setEntidades] = useState([]);
    const [estados, setEstados] = useState([]);
    const [search, setSearch] = useState({ word: "", entidade_id: "" , condicao: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    let { Tipo } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
     const Init = () => {
        Hub.Set("ENTIDADE_FILIADA", (!Tipo ? 2 : Tipo));
        _HandlerSearchChange();
        if(user.nivel!==3) LoadEntidades()
     }
    //#endregion Init


    //#region Loaders
    const LoadEntidades = (word = "") => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Atletas/Entidades", "POST", {word: word, entidade_id: (user.nivel===3?user.entidade_id:'')}, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                setEntidades(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATLIS001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATLIS002)", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {...prev, word: ""});
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Atletas/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: search.word, entidade_id: search.entidade_id, tipo: Tipo, condicao: search.condicao }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];

                _list.map((item, i) => {
                    item.status = item.docs_ok === 0 ? 3 : (item.status === 0 ? 2 : ( item.ativo === 0 ? 0 : 1));
                });

                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Atletas/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: search.word, entidade_id: search.entidade_id, tipo: Tipo, condicao: search.condicao  }, user.token))
                .then((data) => {
                    let _list = [...data.list];

                    _list.map((item, i) => {
                        item.status = item.docs_ok === 0 ? 3 : (item.status === 0 ? 2 : ( item.ativo === 0 ? 0 : 1));
                    });

                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleEdit = async(e, id) => {
        await Hub.Set("ATLETA_TIPO_FILTER", Tipo);
        navigate("/Atleta/"+id);
    }


    const _HandleAddAtleta = () => {
        navigate("/Atleta/");
    }


    const _HandleAtivo = (item) => {
        Promise.resolve(new Request().Run("api/Atletas/Ativo", "POST", { atleta_id: item.atleta_id, ativo: item.ativo}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.atleta_id === item.atleta_id).ativo = data;
            setList(_list);
        });
    }
    //#endregion Handlers





    return (
        <div className="main-content Atletas">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Atletas {Tipo==="1" && (<span>Pendentes</span>)} {Tipo==="0" && (<span>não filiados</span>)}</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                            <div className="align-right">
                                                <button type="button" className={'btn btn-primary ' + (user.nivel>1?'_hide':'')} onClick={_HandleAddAtleta}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                                </button>
                                            </div>

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

                                            {user.nivel!==3 && (
                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="entidade_id" className="form-select" value={search.entidade_id} onChange={_HandleChange}>
                                                        <option value="">Toda entidades</option>
                                                        {
                                                            Array.isArray(entidades) && entidades?.length > 0 && entidades.map((item, i) => (
                                                                <option key={'entidade'+item.id} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 
                                            )}

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="condicao" className="form-select" value={search.condicao} onChange={_HandleChange}>
                                                        <option value="">Qualquer condição</option>
                                                        <option value="1">Deficiente visual</option>
                                                        <option value="2">Deficiente físico</option>
                                                        <option value="4">Deficiente intelectual</option>
                                                        <option value="3">Staff</option>
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Condicao</th>
                                                        <th>CPF</th>
                                                        <th>Entidade</th>
                                                        <th>Status</th>
                                                        {user.nivel !== 3 && (<th>Ativo</th>)}
                                                        <th style={{width:'70px'}}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td>{item.nome}</td>
                                                        <td>{atleta_condicao.find(a => a.id === item.condicao)?.name}</td>
                                                        <td>{item.cpf}</td>
                                                        <td><span className={item.entidade_ativo!==1?'entidade_inativa':''}>{item.entidade}</span></td>
                                                        <td>{atleta_status[item.status]}</td>
                                                        {user.nivel !== 3 && (<td style={{padding:'9px 0 0 0'}}><button type="button" className={'btn btn-sm ' +(item.ativo===1?'btn-primary':'btn-danger')} onClick={() => _HandleAtivo(item)}>{item.ativo===1?'Ativo':'Inativo'}</button></td>)}
                                                        <td>
                                                            <i className="fa fa-edit" onClick={(e) => _HandleEdit( e, item.atleta_id)}></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}