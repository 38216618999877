/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency } from '../../../scripts/StringUtils';
import moment from 'moment/moment';
import './index.css';


let current_element;
let provasData = [];


export default function Lista() {
    const component_name = "admin/eventos_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [provas, setProvas] = useState([]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });
    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init(); 
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        await LoadProvas();
    }
    //#endregion Init


    //#region Loaders
    const LoadEventos = async() => {
        let result = await Req("api/EventosAbertos/List", "POST", "", user.token, 0);

        setList(result);
    }


    const LoadProvas = async() => {
        let provas_nat = await Req("/assets/json/provas.json", "GET", "", "", 0*24*60*1000);
        if(provas_nat.toString() !== "error") {
            provasData = provas_nat;
            console.log(provas_nat);
            setProvas(provas_nat);

            setTimeout(async() => {
                await LoadEventos();
            }, 100);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
        }

    
        return true;
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleEtapa = (item) => {
        navigate("/Inscricao/" + item.etapa_id);
    }
    //#endregion Handlers


    return (
        <div className="main-content EventosAbertos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Eventos</h4>

                                    <div className="row"> 
                                        
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="_table-responsive">
                                        

                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Nome do Evento</th>
                                                        <th>Local</th>
                                                        <th>Cidade</th>
                                                        <th>Inicio</th>
                                                        <th>Fim</th>
                                                        <th>Inscrições até</th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <>
                                                        <tr className="th_evento">
                                                            <td>{item.nome}</td>
                                                            <td>{item.local}</td>
                                                            <td>{item.cidade}-{item.uf}</td>
                                                            <td>{moment(item.inicio).format('DD/MM/YYYY')}</td>
                                                            <td>{moment(item.fim).format('DD/MM/YYYY')}</td>
                                                            <td>{moment(item.fim_insc).format('DD/MM/YYYY')}</td>
                                                        </tr>

                                                        <tr key={'tr_etapa' + i} className="pointer th_etapa">
                                                            <td colSpan={3}>Nome da Etapa</td>
                                                            <td>Modalidade</td>
                                                            <td>Valor Inscrição</td>
                                                            <td>Provas</td>
                                                        </tr>

                                                        {item.etapas.map((etapa, j) => (
                                                            <>
                                                                <tr key={'tr_etapa' + i} className="pointer">
                                                                    <td colSpan={3} onClick={() => _HandleEtapa(etapa)} role="button" tabindex={i} aria-label={etapa.nome}>{etapa.nome}</td>
                                                                    <td onClick={() => _HandleEtapa(etapa)}>{etapa.modalidade}</td>
                                                                    <td onClick={() => _HandleEtapa(etapa)}>{Currency(user.filiado === 1 ? etapa.valor_afiliado : etapa.valor_nafiliado)}</td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <a className="text-muted dropdown-toggle _font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                            {etapa.provas.length} <i className="mdi mdi-dots-horizontal"></i> 
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                {
                                                                                    etapa?.provas?.length > 0 && etapa?.provas?.map((prova, k) => (
                                                                                        <a key={prova} className="dropdown-item pointer">{provas[etapa.modalidade.toLowerCase().indexOf('atletismo')>-1?'atletismo':'natacao']?.find(p => p.prova_id === prova.prova_id)?.nome} {prova.niveis!==''?'(':''} {prova.niveis} {prova.niveis!==''?')':''}</a>
                                                                                    ))
                                                                                }
                                                                                  
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </>
                                                        ))}
                                                    </>    
                                                     

                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        <br />  
                                        <br />                           
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}