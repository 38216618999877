/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import './AtletasPendentes.css';


export default function AtletasPendentes() {
    const component_name = "admin/atletaspendentes_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [estados, setEstados] = useState([]);
    const [search, setSearch] = useState({ word: "", uf: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        _HandlerSearchChange();
    }
    //#endregion Init



    //#region Handlers
    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Home/AtletasPendentes/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                _list.map((item, i) => {
                    if(item.entidade_destino !== "") {
                        item.status = 0;
                    } else if(item.ativo === 0) {
                        item.status = 1;
                    }
                });

                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Home/AtletasPendentes/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    _list.map((item, i) => {
                        if(item.entidade_destino !== "") {
                            item.status = 0;
                        } else if(item.ativo === 0) {
                            item.status = 1;
                        }
                    });
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleAtivar = (item) => {
        Promise.resolve(new Request().Run("api/Home/AtletaAtivo", "POST", { atleta_id: item.atleta_id, ativo: 1}, user.token))
        .then((data) => {
            _HandlerSearchChange();
        });
    }

    const _HandleTransferir = (item, efetivar) => {
        Promise.resolve(new Request().Run("api/Home/AtletaTransferencia", "POST", { transferencia_id: item.transferencia_id, efetivar: efetivar}, user.token))
        .then((data) => {
            _HandlerSearchChange();
        });
    }


    const _HandleGoToDocs = (item) =>  {
        navigate("/Atleta/" + item.atleta_id)
    }


    const _HandleAtleta = (item) =>  {
        navigate("/Atleta/" + item.atleta_id)
    }
    //#endregion Handlers



    return (
        <div className="card AtletasPend">
            <div className="card-body pb-3">
                <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-2">Atletas pendentes</h5>
                    </div>
                    <div className="flex-shrink-0">
                    
                    </div>
                </div>

                <div id="scroll_atletas" className="">
                    <div className="table-responsive">

                        <InfiniteScroll
                            dataLength={list.length}
                            next={_fetchMoreData}
                            hasMore={hasMore}
                            loader={
                                <p style={{ textAlign: "center", padding:'25px' }}>
                                    <b>Carregando...</b>
                                </p>
                            }
                            scrollableTarget="scroll_atletas"
                            
                            endMessage={
                                <p style={{ textAlign: "center", padding:'25px' }}>
                                    <b>Não há mais dados</b>
                                </p>
                            }>    


                            <table className="table _project-list-table table-nowrap align-middle _table-borderless mb-2">
                                <thead>
                                    <tr>
                                        <th scope="col">Nome</th>
                                        <th scope="col">CPF</th>
                                        <th scope="col">Entidade Origem</th>
                                        <th scope="col">Entidade Destino</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Data</th>
                                        {user.nivel !== 3 && (<th scope="col"></th>)}
                                    </tr>
                                </thead>

                                <tbody>
                                    {Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                    <tr key={'apend'+i} style={{cursor:'pointer'}}>
                                        <td className='at-nome at' onClick={() => _HandleAtleta(item)}>
                                            {item.nome}
                                        </td>
                                        <td className='at-cpf at' onClick={() => _HandleAtleta(item)}>{item.cpf}</td>
                                        <td className='at-origem' onClick={() => _HandleAtleta(item)}>
                                            {item.entidade_origem}
                                        </td>
                                        <td className='at-destino at' onClick={() => _HandleAtleta(item)}>
                                            {item.entidade_destino}
                                        </td>
                                        <td className='at-status at' onClick={() => _HandleAtleta(item)}>
                                            <p className="mb-0">
                                                {item.status === 0 && (<>Em transferência</>)} 
                                                {item.status === 1 && (<>Inativo</>)} 
                                                {item.status === '' && item.docs_ok === 0 && (<>Docs pendentes</>)} 
                                            </p>
                                        </td>
                                        <td className='at-data at' onClick={() => _HandleAtleta(item)}>
                                            {item.data}
                                        </td>

                                        {user.nivel !== 3 && (
                                            <td className='at-actions'> 
                                                <div className="dropdown">
                                                    <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                        <i className="mdi mdi-dots-horizontal"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        {item.status === 0 && (<>
                                                            <a className="dropdown-item pointer" onClick={() => _HandleTransferir(item, 1)}>Efetivar transferência</a>
                                                            <a className="dropdown-item pointer" onClick={() => _HandleTransferir(item, 0)}>Negar transferência</a>
                                                            </>)} 

                                                        {item.status === 1 && (<>
                                                        <a className="dropdown-item pointer" onClick={() => _HandleAtivar(item)}>Ativar Atleta</a>
                                                        </>)} 

                                                        {item.status === '' && item.docs_ok === 0 && (<>
                                                        <a className="dropdown-item pointer" onClick={() => _HandleGoToDocs(item)}>Ver documentos</a>
                                                        </>)}       
                                        
                                                    </div>
                                                </div>
                                            </td>
                                        ) }
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </InfiniteScroll>

                    </div>          
                </div>
            </div>
        </div>
    )

}