/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { UploadService } from '../../../scripts/UploadService';
import { AppServer } from '../../../scripts/Loadbalancer';
import moment from 'moment/moment';


let current_element;


export default function Lista() {
    const component_name = "admin/transparencia_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", tipo_id: "" });
    const [transparencia, setTransparencia] = useState({ nome: "", tipo_id: 1, date_doc: moment().format('YYYY-MM') });

    const hiddenFileInput = useRef(null);
    const _uploadedFiles = useRef([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    const tipos = [
        {tipo_id: 1, nome: "Calendário"},
        {tipo_id: 2, nome: "Boletins e Comunicados Oficiais"},
        {tipo_id: 3, nome: "Estatuto Social"},
        {tipo_id: 4, nome: "Atas"},
        {tipo_id: 6, nome: "Transparência Adm. / Financeiro"},
        {tipo_id: 7, nome: "Goalball"},
        {tipo_id: 8, nome: "Futebol"},
        {tipo_id: 9, nome: "Judô"},
        {tipo_id: 10, nome: "Atletismo"},
        {tipo_id: 11, nome: "Natação"},
        {tipo_id: 12, nome: "Xadrez"}
    ]


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadList();
    }
    //#endregion Init



    //#region Loaders
    const LoadList = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Transparencia/List/0/0/0", "POST", search, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                let old_tipo_id = "";
                let _list = data.list
                _list.map((item, i) => {
                    if(item.tipo_id !== old_tipo_id) {
                        old_tipo_id = item.tipo_id;
                        item.tipo = tipos.find(t => t.tipo_id === item.tipo_id).nome;
                    } else {
                        item.tipo = "";
                    }

                    let _dt = item.date_doc.split('-');
                    item.date_doc = _dt[1] + "/" +_dt[0]
                });
                console.log(_list);
                setList(_list );
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRALST001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRALST002)", "error");
        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleFChange = (event) => {
        setTransparencia(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandlerSearchChange = (event) => {
        LoadList();
    }


    const _HandleDelete = (item, i) => {
        Promise.resolve(new Request().Run("api/Transparencia/Delete", "POST", { transparencia_id: item.transparencia_id}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.splice(i, 1);
            setList(_list);
        })
    }


    const _HandleUpload = () => {
        hiddenFileInput.current.click();
    }


    const _HandleFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
           
        };
        reader.readAsDataURL(files[0]);
        Upload(e);
    }
    //#endregion Handlers


    //#region Upload
    const Upload = async(event) => {
        let _transparencia = {...transparencia};
        console.log(_transparencia);

        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.Data = _transparencia;
        upload.OnEnd = (result) => {
            c++;

            if(c === event.target.files.length) {
                LoadList(); 
                setTransparencia(prev => prev = {...prev, nome: ""});   
                setPageLoading(false);
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

       
        await upload.PopUpload(AppServer("/api/Transparencia") + "api/Transparencia/Upload", user.token);
    }
    //#endregion Upload

    return (
            <div className="main-content Transparencia">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header justify-content-between d-flex align-items-center">
                                        <h4 className="card-title">Transparencia</h4>
                                        
                                        {user.nivel !== 3 && (
                                            <div className="row"> 
                                                <div className="col-xl-12"> 
                                                    

                                                    <div className="align-right mr-10">
                                                        <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                            <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                        </button>
                                                    </div>

                    

                                                    <div className="search-box align-right mr-10">
                                                        <div className="position-relative">
                                                            <select id="tipo_id" className="form-select" value={search.tipo_id} onChange={_HandleChange}>
                                                                <option value="">Todas áreas</option>
                                                                {
                                                                    Array.isArray(tipos) && tipos?.length > 0 && tipos.map((item, i) => (
                                                                        <option key={'tipo_'+item.tipo_id} value={item.tipo_id}>{item.nome}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div> 

                                                    <div className="search-box align-right mr-10">
                                                        <div className="position-relative">
                                                            <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                            <i className="bx bx-search search-icon"></i>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                           
                                        )}
                                        

                                       
                                    </div>



                                                          
                                    <div className="card-body pb-2">
                                        <div className='row'>
                                            <div className={user.nivel>1?'col-sm-12':'col-sm-9'}>
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Arquivo</th>
                                                                <th>Data</th>
                                                                <th>Download</th>
                                                                {user.nivel!==3 && (
                                                                <th style={{width:'70px'}}></th>
                                                                )}
                                                            </tr>
                                                        </thead>


                                                        <tbody>
                                                        
                                                        {list.map((item, i) => (
                                                            <>
                                                                {
                                                                    item.tipo !== '' &&  (
                                                                        <tr className="tr_head">
                                                                            <td colSpan={5}><b>{item.tipo}</b></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                
                                                                
                                                                <tr key={'tr' + i} className="pointer">
                                                                    <td>{item.nome}</td>
                                                                    <td>{item.date_doc}</td>
                                                                    <td><a href={'../storage/docs/'+item.doc} target="_blank" rel="noreferrer">Download</a></td>
                                                                    {user.nivel!==3 && (<td>
                                                                        <i className="fa fa-trash" onClick={(e) => _HandleDelete( item, i)}></i>
                                                                    </td>)}
                                                                </tr>
                                                            </>
                                                        ))}
                                                        
                                                        </tbody>
                                                    </table>
                                                    <br /> <br />                     
                                                </div>
                                            </div>

                                            <div className={'col-sm-3 ' + (user.nivel>1?'hide':'')} >
                                                <div>
                                                    <center>
                                                    <h4>Upload de Arquivo</h4>
                                                    </center>
                                                </div>
                                                <br />   

                                                <div>
                                                    <label>Área</label>
                                                    <select id="tipo_id" className="form-select" value={transparencia.tipo_id} onChange={_HandleFChange}>
                                                            {
                                                                Array.isArray(tipos) && tipos?.length > 0 && tipos.map((item, i) => (
                                                                    <option key={'qtipo_'+item.tipo_id} value={item.tipo_id}>{item.nome}</option>
                                                            ))
                                                            }    
                                                    </select>            
                                                </div> 
                                                <br /> 

                                                <div>
                                                    <label>Nome do arquivo</label>
                                                    <input id="nome" className="form-control" value={transparencia.nome} onChange={_HandleFChange}/>      
                                                </div> 
                                                <br />   

                                                <div>
                                                <label>Mês e Ano</label><br></br>
                                                    <input type="month" className="form-control" id="date_doc" name="date_doc" value={transparencia.date_doc} onChange={_HandleFChange}/>
                                                </div> 
                                                <br />              

                                                <div>
                                                    <input type="file" ref={hiddenFileInput} onChange={_HandleFileChange} style={{display: 'none'}} accept=".*"/>
                                                    <button type="button" className={'btn btn-primary ' + (user.nivel>1 || transparencia.nome==='' || transparencia.date_doc === '' || transparencia.tipo_id === ''?'hide':'')} style={{width: '100%'}}  onClick={_HandleUpload}>
                                                        <i className="mdi mdi-star-plus"></i> &nbsp;Enviar Arquivo
                                                    </button>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>


                        
                        </div>
                    </div>
                </div>
            </div>

   
            
       
    )
}