/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';


let current_element;


export default function Lista() {
    const component_name = "admin/modalidades_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "" });
    const [modalidade, setModalidade] = useState("");


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: ""});


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadList();
    }
    //#endregion Init



    //#region Loaders
    const LoadList = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Modalidades/List/0/0/0", "POST", search, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                setList(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (MODLST001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (MODLST002)", "error");
        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleDelete = (item, i) => {
        Promise.resolve(new Request().Run("api/Modalidades/Delete", "POST", { modalidada_id: item.modalidade_id}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.splice(i, 1);
            setList(_list);
        })
    }


    const _HandleAtivo = (item) => {
        Promise.resolve(new Request().Run("api/Modalidades/Ativo", "POST", { modalidada_id: item.modalidade_id, ativo: item.ativo}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.modalidade_id === item.modalidade_id).ativo = data;
            setList(_list);
        })
    }


    const _HandleInclude = () => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Modalidades/Save", "POST", { nome: modalidade}, user.token))
        .then((data) => {
            setPageLoading(false);
            setModalidade("");
            LoadList();
        })
    }


    const _HandleChange = (event) => {
        setModalidade(event.target.value);
    }
    //#endregion Handlers




    return (
        <div className="main-content Modalidades">
            <div className="page-content">
                <div className="container-fluid card">
                    <br />
                    <div className="row">
                        <div className="col-sm-6">
                            <div className=" card-body pb-0">
                                <div className="table-responsive selected-list list">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Modalidade</th>
                                                <th style={{width:'70px'}}>Status</th>
                                                {/*<th style={{width:'40px'}}></th>*/}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                                    <tr key={'slist'+i}>
                                                        <td><span>{item.nome}</span></td>
                                                        <td><button type="button" className={'btn btn-sm ' +(item.ativo===1?'btn-primary':'btn-danger')} onClick={() => _HandleAtivo(item)}>{item.ativo===1?'Ativo':'Inativo'}</button></td>
                                                        {/*<td><button type="button" className="btn btn-sm btn-primary" onClick={() =>_HandleDelete(item, i)}><i class="fa fa-trash"></i></button></td>*/}
                                                    </tr>
                                                ))
                                            }

                                            {
                                                list.length === 0 && (
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <br/><br/>
                                                            <center>Nenhuma modalidade adicionada</center>
                                                            <br/><br/>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>

                                    
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="pricing-box">
                                <br />
                                <div className="input-group">
                                    <input type="text" className="form-control" id="new_modalidade" value={modalidade} placeholder="Nome da modalidade" onChange={_HandleChange}/>
                                    <button className="btn btn-primary" onClick={_HandleInclude} style={{width:'90px'}}>Incluir</button>
                                </div>
                                <br />
                                
                            </div>
                        </div>

                    </div>

                    <br />
                    <br />
                </div>
            </div>
        </div>

   
            
       
    )
}