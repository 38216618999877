import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { cnpj as CnpjValidate} from 'cpf-cnpj-validator'; 
import ReactGA from 'react-ga';
import './index.css';


import Lista from '../Entidades/Lista';


let current_element;
let cliente_auto_fill = false;


export function Perfil() {
    const component_name = "admin/perfil";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValidaded, setIsFormValidaded] = useState(false);

    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _formUserId = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());
    const [tfa, setTfa] = useState(false);
    const [qr2fa, setQr2fa] = useState("");
    const [code2fa, setCode2fa] = useState("");
    const [alerts, setAlerts] = useState([]);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                 
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init


    //#region Loaders
    const LoadUfs = (uf = 26) => {
        Promise.resolve(new Request().Run("/assets/json/estados.json", "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("uf_codigo", data);
            await _formUserRef.current.Value("uf_codigo", uf);
            LoadCidades(uf)

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadCidades = (uf_codigo, city = "") => {
        Promise.resolve(new Request().Run("api/Perfil/Cidades?uf_codigo=" + uf_codigo, "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("cidade_codigo", data);

            if(city !== "") {
                city = city.toLocaleUpperCase();
                let _id = data.find(c => c.name === city).id;
                await _formUserRef.current.Value("cidade_codigo", _id);
            }

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }



    const LoadFuncoes = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Diretorias/Funcoes", "GET", "", user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                data.unshift({id: 0, name: "Escolha uma diretoria"})
                await _formUserRef.current.Select("funcao_id", data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD01)", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD002)", "error");
        });
    }
    //#endregion Loaders



    //#region Form Handlers
    const login = async (value) => {
        if(!cliente_auto_fill) return;
        current_element = "#login";

        if(! _formUserId.current) return;
        Promise.resolve(new Request().Run("api/Perfil/CheckLogin", "POST", {login: value, entidade_id: _formUserId.current}, user.token, 0)).then(async(data) => {
            current_element = "";
            if(data.toString() === "exists") {
                window.swal("Alerta", "Esse login já está associado à uma entidade existente! ", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ENTCAD001)", "error");
        });
    }


    const cnpj = async (value) => {
        if(!cliente_auto_fill) return;
        setIsFormValidaded(false);
    }


    const blur_cnpj = async (value) => {
        if(!CnpjValidate.isValid(value) && value !== "11.111.111/1111-11") {
            window.swal("Alerta", "CNPJ inválido", "error");
            return;
        }
        if(! _formUserId.current) return;

        current_element = "#cnpj";
        Promise.resolve(new Request().Run("api/Perfil/CheckCNPJ", "POST", {cnpj: value, entidade_id:  _formUserId.current}, user.token, 0)).then(async(data) => {
            current_element = "";
            if(data.toString() === "exists") {
                window.swal("Alerta", "Esse CNPJ já está associado à uma entidade existente! ", "error");
            } else {
                setIsFormValidaded(true);
            }

        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ENTCAD002)", "error");
        });
    }
        

    const uf_codigo = value => {
        if(!value === "") LoadCidades(value);
    }

    
    const cep = async value => {
        //console.log(cliente_auto_fill);
        if(!cliente_auto_fill) return;

        if(value.length === 9 ) {
            Promise.resolve(new Request().Run("https://brasilapi.com.br/api/cep/v1/" + value.replace('-',''), "GET", "", "", 0)).then(async(data) => {
                if(data.toString() !== "error") {
                    await _formUserRef.current.Value("endereco", data.street);
                    await _formUserRef.current.Value("bairro", data.neighborhood);
                    let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                    let _uf = _uf_options.find(u => u.sigla === data.state).id;
                    await _formUserRef.current.Value("uf_codigo", _uf);
                    LoadCidades(_uf, data.city);
                }
            }).catch(() => {
            
            });
        }
    }

    
    const OnFormLoading = async() => {
        cliente_auto_fill = false;
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            let user_data = await new Request().Run("api/Perfil/Load?id=" + (user.nivel===3?user.entidade_id:user.user_id), "GET", "", user.token);
            if(user_data !== undefined) {
                _formUserId.current = (user.nivel===3?user_data.entidade_id:user.user_id);
                await _formUserRef.current.Load(user_data);
                if(user.nivel===3) LoadUfs(user_data.uf_codigo);
                if(user.nivel===2) LoadFuncoes();
                setTfa(user_data.tfa);
                if(user.nivel===3) ValidadeCNPJ(user_data.cnpj);
            } else {
                if(user.nivel===3) LoadUfs();
                if(user.nivel===2) LoadFuncoes();
            }
            
            setPageLoading(false);
            window.scrollTo({top: 0, behavior: 'smooth'});

            setTimeout(async() => {
                await _formUserRef.current.Value("senha", "a");
                await _formUserRef.current.Value("resenha", "");
                setTimeout(async() => {
                    await _formUserRef.current.Value("senha", "");
                }, 100);

                
                forceUpdate();
            }, 100);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers



    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        if(validade) {
            let _senha = _formUserRef.current.GetValue("senha");
            let _resenha =  _formUserRef.current.GetValue("resenha");

            if(_senha !== "" && _senha !== _resenha ) {
                window.swal("Alerta", "Senha e repetição da senha não conferem", "error");
                return;
            }

            setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Perfil/Save", {entidade_id: + _formUserId.current}, user.token);
            setPageLoading(false);

            await _formUserRef.current.Value("senha", "");
            await _formUserRef.current.Value("resenha", "");

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Usuário já registrado", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            window.swal("Sucesso", "Informações atualizadas", "success");
            await Refresh();
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }


    const _HandleTFAClick = (e) => {
        setTfa(e.target.checked);
        Promise.resolve(new Request().Run("api/Perfil/TFA", "POST", {tfa: e.target.checked}, user.token)).then((data) => { }).catch(() => { });
    }


    const _HandleTFAQRClick = (e) => {
        setTfa(e.target.checked);
        Promise.resolve(new Request().Run("api/Perfil/QRTFA", "POST", {tfa: e.target.checked}, user.token)).then((data) => { 
            setQr2fa(data)
        }).catch(() => { });
    }


    const _HandleChangeTFA = (e) => {
        setCode2fa(e.target.value);
        if(e.target.value.length === 6) {
            Promise.resolve(new Request().Run("api/Perfil/TFACheck", "POST", {tfa: e.target.value}, user.token)).then((data) => { 
                setCode2fa("");
                if(data.toString() === "loaded") {
                    setQr2fa({tfa: "", code:""});
                    setTfa(true);
                    window.swal("Sucesso", "Autenticação de 2 fatores ativada", "success");
                } else {
                    setTfa(false);
                    window.swal("Alerta", "Código para ativação incorreto", "error");
                }
                
            }).catch(() => { });
        }
    }


    const _HandlerAlert = (alerts) => {
        setAlerts(alerts);
    }
    //#endregion Handlers


    //#region Funtions
    const ValidadeCNPJ = (value) => {
        if(_formUserId.current) {
            if(!CnpjValidate.isValid(value) && value !== "11.111.111/1111-11") {
                setIsFormValidaded(false);
                window.swal("Alerta", "CNPJ inválido", "error");
            } else {
                setIsFormValidaded(true);
            }
        }
    }
    //#endregion Functions



    return(
        <div className="main-content Perfil">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Meu perfil {Array.isArray(alerts) && alerts.length > 0 && (<span style={{color:'red'}}> &nbsp; (Documentos faltando: {alerts.join(', ')})</span>)}</h4>
                                </div>

                             

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('perfil', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'perfil'} url={user.nivel===3?'/assets/forms/perfil.json':(user.nivel===2?'/assets/forms/perfil_diretoria.json':'/assets/forms/perfil_user.json')} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                 
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>

                                    { user.nivel === 3 && _formUserId.current && (
                                        <Lista EntidadeId={_formUserId.current} OnAlert={_HandlerAlert}/>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-3">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Autenticação de 2 fatores</h4>
                                    <input type="checkbox" className="custom-control-input" id="tfa" checked={tfa} onClick={_HandleTFAQRClick} />
                                    
                                </div>

                                <div className="card-body pb-2">
                                    <div className="card-header border-0 pb-0 pt-0">
                                        <small><a style={{color:'#0000ff', float:'right'}} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US&pli=1" target="_blank">Google Authenticator</a></small>
                                    </div>
                                    <br />

                                    <div className="card-body portfolios-card">
                                       
                                        {
                                            qr2fa.tfa && qr2fa.tfa !== "" && (<>
                                                <center>
                                                    <img src={qr2fa.tfa} alt="" style={{width:'100%', height:'auto'}}/>
                                                    <br />
                                                    <input id="code2fa" type="text" placeholder="" className="form-control"  value={code2fa} onChange={_HandleChangeTFA}/>

                                                    <br />
                                                </center>
                                                <small>
                                                <p>Escaneie o QR Code no app Google Authenticator e digite o código fornecido para ativação. Após ativado em cada novo login no sistema será pedido um código atualizado.</p>
                                                <p>Caso desinstale o app, você deve acessar a recuperação de senha para desativar a autenticação de 2 fatores</p>
                                                </small>
                                                </> )
                                        }
                                         
									</div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>
    )
}