export const CacheDB = {
    request: null,
    objectStore: null,
    db: null,
    transaction: null,
  
    Initialize: async(name) => {
      return new Promise((resolve, reject) => {
        CacheDB.request = window.indexedDB.open("fpdc_database", 1);
  
        CacheDB.request.onerror = function(event) {
          reject(event.target.errorCode);
        };
  
  
        CacheDB.request.onsuccess = function(event) {
          CacheDB.db = event.target.result;
          resolve("Banco de dados aberto com sucesso!");
        };
  
        CacheDB.request.onupgradeneeded = function(event) {
          CacheDB.db = event.target.result;
          let objectStore = CacheDB.db.createObjectStore("fpdc_store", { keyPath: "chave" });
          console.log("Object store criado com sucesso!");
        };
      })
    },
  
  
    Set: async(chave, valor) => {
      //console.log("Set", chave, valor);
      if (!CacheDB.db) {
        console.log("Erro: banco de dados não está pronto.", chave, valor);
        return;
      }
  
      return new Promise((resolve, reject) => {
        let transaction = CacheDB.db.transaction(["fpdc_store"], "readwrite");
        let objectStore = transaction.objectStore("fpdc_store");
        objectStore.put({ chave: chave, valor: valor });
        resolve();
      })
  
    },
  
  
    Get: async(chave) => {
      if (!CacheDB.db) {
        console.log("Erro: banco de dados não está pronto.", chave);
        return;
      }
  
      return new Promise((resolve, reject) => {
        let transaction =  CacheDB.db.transaction(["fpdc_store"], "readwrite");
        let objectStore = transaction.objectStore("fpdc_store");
        let request = objectStore.get(chave);
  
        request.onsuccess = function(event) {
          let valor = event.target.result;
          if (valor) {
            resolve(valor.valor);
          } else {
            resolve(null);
          }
        };
      
        request.onerror = function(event) {
          console.log("Erro ao recuperar o valor:", event.target.error);
          reject(null);
        };
      });
    },
  
  
    JSet: async(chave, value) => {
      CacheDB.Set(chave, JSON.stringify(value));
      //await CacheDB.Set(chave, value);
    },
  
  
    JGet: async(chave) => {
      return JSON.parse(await CacheDB.Get(chave));
      //return await CacheDB.Get(chave);
    },
  
  
    Remove: async(chave) => {
      try {
        let transaction =  CacheDB.db.transaction(["fpdc_store"], "readwrite");
        let objectStore = transaction.objectStore("fpdc_store");
        let request = objectStore.delete(chave);
      } catch(err) {
        console.log(err);
      }
    },
  
  
    Delete: (key) => CacheDB.Remove,
  }
  
  
 