/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';
import Inscricoes from './Inscricoes';
import AtletasPendentes from './AtletasPendentes';
import FichasPendentes from './FichasPendentes';
import Info from './Info';


export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                    RegisterHub();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        /*setTimeout(() => {
            window.draw_column_chart();
            window.draw_chart_donut();
            window.draw_chart_area();
            window.draw_slider();
            window.draw_sales_countries();
            window.draw_sparklines(1);
            window.draw_sparklines(2);
            window.draw_sparklines(3);
            window.draw_sparklines(4);
            window.draw_sparklines(5);
            window.draw_mini_1();
        }, 10);*/
    }
    //#endregion Init


    //#region HubObjects
    const RegisterHub = () => {
        /*Hub.Set("HOME_INIT_DRAW", () => { 
            Init(); 
        }, component_name);*/

        
    }
    //#endregion HubObjects


    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {
                        user.nivel !== 3 && (
                            <div className="row">
                                <div className="col-xl-12">
                                    <Info />
                                </div>
                            </div>
                        )
                    }


                    {
                        user.nivel === 3 && 
                            <div className="row">
                              
                                <div className="col-xl-12">
                                    <div className='top_aviso'>
                                        <h4><b>Importante!!</b> A atualização para o novo sistema da FPDC incluiu evoluções tecnológicas e estruturais, por esse motivo pedimos às entidades filiadas e não filiadas a conferência e atualização de dados institucionais e dos atletas para que os novos eventos e inscrições ocorram sem problemas.</h4>
                                    </div>
                                </div>
                              
                            </div>
                    }


                    <div className="row">
                        <div className="col-xl-4">
                            <Inscricoes />
                        </div>

                        <div className="col-xl-8">
                            <AtletasPendentes />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-4">
                            
                        </div>

                        <div className="col-xl-8">
                            {user.nivel===3 && (<FichasPendentes />)}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}