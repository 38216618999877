/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import {Currency, UnCurrency } from '../../../scripts/StringUtils'
import { WaitObject } from '../../../scripts/Wait';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './index.css';

import Time from './Time';



const deficiencias = [
    {id:0, name :"deficiente visual, físico ou intelectual"},
    {id:1, name :"deficiente visual"}, 
    {id:2, name :"deficiente físico"},
    {id:4, name :"deficiente intelectual"},    
];

let current_element;
let provasData = [];
let etapas_atletas_count = {timeA:0, timeB:0, timeAOK: false, timeBOK: false, staffA: [], staffB: [], fichaA:"", fichaB:"" };

export default function Inscricao({...props}) {
    const component_name = "admin/inscricao_etapa";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [etapa, setEtapa] = useState({});
    const [percent, setPercent] = useState(0);
    const [faltam, setFaltam] = useState(0);
    const [provas, setProvas] = useState([]);
    const [search, setSearch] = useState({entidade_id: "", filiado: 0});
    const [selprovas, setSelProvas] = useState([]);
    const [entidades, setEntidades] = useState([]);
    const [total, setTotal] = useState(0);
    const [atletasCount, setAtletasCount] = useState(0);
    const [atletas, setAtletas] = useState([]);
    const [timeEnded, setTimeEnded] = useState(true);
    const [fichaStatus, setfichaStatus] = useState(0);
    const [sendFichaEnable, setSendFichaEnable] = useState(false);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();


    const {Id, Eid} = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    etapas_atletas_count = {timeA:0, timeB:0};
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        await LoadEntidades();
        await LoadProvas();
        await LoadEtapa();
    }
    //#endregion Init


    //#region Loaders
    const LoadProvas = async() => {
        let _provas = await Req("/assets/json/provas.json", "GET", "", "", 0*24*60*1000);
        if(_provas.toString() !== "error") {
            /*let _old = "";
            _provas.natacao.map(item => {
                if(item.estilo !== _old) {
                    _old = item.estilo;
                } else {
                    item.estilo = "";
                }
            })*/
            provasData = _provas;
            setProvas(_provas);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
        }

        return true;
    }


    const LoadEtapa = async() => {
        if(!Id) return;

        let etapa = await Req("api/Inscricao/Load?id=" + Id, "GET", "", user.token, 0*24*60*1000);
        if(etapa.toString() !== "error") {
            let dt_ini = moment(etapa?.inicio_insc+' 00:00:00');
            let dt_fim = moment(etapa?.fim_insc+' 00:00:00');
            //let dt_fim = moment('2023-12-06 00:00:00');
            let diff_all = Math.abs(dt_ini.diff(dt_fim, 'days')) ;
            let diff_dt = Math.abs(dt_ini.diff(moment(), 'days'));

            
            let diff_falta_signed = dt_fim.diff(moment(), 'days');

            
            if(diff_falta_signed<0) {
                setTimeEnded(diff_falta_signed<0);
                window.swal("Alerta", "Inscrições encerradas para essa etapa!", "error");
                //navigate("/Home");
                //return;
            } else {
                setTimeEnded(false);
            }
            

            let diff_faltam = Math.abs(diff_falta_signed);
            setFaltam(diff_faltam);

            let perc = Math.ceil(diff_dt * 100)/diff_all;
            if(perc > 100 ) perc = 100;
            console.log(etapa?.modalidade);
            setSelProvas(provasData[(etapa?.modalidade?.toLowerCase()?.indexOf("natação")>-1?"natacao":"atletismo")])
            setPercent(perc);
            setEtapa(etapa);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
        }
        return true;
    }


    const LoadEntidades = async(word = "") => {
        setPageLoading(true);

        await Promise.resolve(new Request().Run("api/Inscricao/Entidades", "POST", {word: word, entidade_id: (user.nivel===3?user.entidade_id:''), etapa_id: Id}, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                setEntidades(data);

                if(!data || data?.length === 0) {
                    window.swal("Alerta", "Entidade não tem permissão para inscrição!", "error");
                } else {
                    setTimeout(() => {
                        if(Eid) {
                            setSearch(prev => prev = {...prev, entidade_id: Eid, filiado: user.filiado});
                        } else {
                            setSearch(prev => prev = {...prev, entidade_id: data[0].entidade_id, filiado: data[0].filiado});
                        }
                    }, 100);
                }
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATTRA01)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATTRA002)", "error");
        });

        return true;
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleCadastro = () => {
        if(search.filiado === 0) {
            navigate("/Atletas/0");
        } else {
            navigate("/Atleta");
        }
        
    }

    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleAtletasChange = (count, time, isOk, staff, ficha_id, valor) => {
        console.log(count, time, isOk, staff, ficha_id, valor);

        if(time===0) {
            etapas_atletas_count.timeA = count;
            etapas_atletas_count.timeAOK = isOk;
            etapas_atletas_count.staffA = staff || [];
            etapas_atletas_count.fichaA = ficha_id;
        } else {
            etapas_atletas_count.timeB = count;
            etapas_atletas_count.timeBOK = isOk;
            etapas_atletas_count.staffB = staff || [];
            etapas_atletas_count.fichaB = ficha_id;
        }

        
        let _count = 0;
        let _count1 = 0;
        let _count2 = 0;
        let _discount = 0;
        let _staff_new = [];
        let _staff_arr = etapas_atletas_count.staffB ? etapas_atletas_count.staffA.concat(etapas_atletas_count.staffB) : etapas_atletas_count.staffA;


        _staff_arr.map((item, i) => {
            if(item && item?.atleta_id !== "" && item?.atleta_id !== 0) {
                //if(!item.pay || item.pay === 0) _discount++;

                let _index = _staff_new.findIndex(s => s.atleta_id === item?.atleta_id);
                if(_index === -1) {
                    _staff_new.push(item);
                    _count++;
                }

                if(item?.staff === 1) {
                    _count1++;
                } else  if(item?.staff === 2) {
                    _count2++;
                }
            }
        });

        //console.log(etapas_atletas_count.timeAOK, etapas_atletas_count.timeBOK);
        //console.log(etapa.modalidade)


        if(etapas_atletas_count.timeAOK && !etapas_atletas_count.timeBOK) {
            //console.log(etapas_atletas_count.timeB + " -------------------------------------------");
            if(etapas_atletas_count.timeB === 0) {
                let chk_empty_B = true;
                if(etapas_atletas_count.staffB && etapas_atletas_count.staffB.length > 0) {
                    etapas_atletas_count.staffB.map((item, i) => {
                        if(item.atleta_id === '') chk_empty_B = false;
                    });
                    etapas_atletas_count.timeBOK = true;
                } else {
                    etapas_atletas_count.timeBOK = true;
                }
            }
        }

        //console.log(etapas_atletas_count.timeA, etapas_atletas_count.timeB, etapas_atletas_count.timeAOK, etapas_atletas_count.timeBOK, _count1, _count2);

        setTotal(valor);
        //setTotal((etapas_atletas_count.timeA+etapas_atletas_count.timeB+_count-_discount)*((entidades?.find(e => e.entidade_id===parseInt(search.entidade_id))?.filiado === 1?etapa?.valor_afiliado:etapa?.valor_nafiliado)))
        //setSendFichaEnable(etapas_atletas_count.timeAOK && etapas_atletas_count.timeBOK && ((_count1 >= 2 && _count2 === 0) || (_count1 >= 2 && _count2 >= 2)));
        
        setSendFichaEnable(etapas_atletas_count.timeAOK && etapas_atletas_count.timeBOK);
        setAtletasCount(etapas_atletas_count.timeA+etapas_atletas_count.timeB+_count);

    }


    const _HandleFichaLoad = (status, time, ficha_id) => {
        if(status === 1) setfichaStatus(status);
        if(time === 0) {
            etapas_atletas_count.fichaA = ficha_id;
        } else {
            etapas_atletas_count.fichaB = ficha_id;
        }

    }


    const _HandleSendClose = () => {


        window.swal({
            title: "Alerta!",
            text: "Deseja realmente enviar essa ficha?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: false
         },
         function(isConfirm){
           if (isConfirm){
                let result = {a:false, b: false};

                setTimeout(async() => {
                    if(etapas_atletas_count.fichaA && etapas_atletas_count.fichaA !== "" && etapas_atletas_count.timeAOK) await Req("api/Inscricao/SendFicha", "POST", { entidade_id: search.entidade_id, evento_id: etapa?.evento_id, etapa_id: Id, ficha_id: etapas_atletas_count.fichaA, time_id: 0}, user.token)
                    .then(() => {result.a = true})
                    .catch(() => {});

                    /*if(etapas_atletas_count.fichaB && etapas_atletas_count.fichaB !== "" && etapas_atletas_count.timeBOK) await Req("api/Inscricao/SendFicha", "POST", { entidade_id: search.entidade_id, evento_id: etapa?.evento_id, etapa_id: Id, ficha_id: etapas_atletas_count.fichaB, time_id: 1}, user.token)
                    .then(() => {result.b = true})
                    .catch(() => {});*/

                   
                    window.swal("Ficha enviada", "Inscrições enviadas com sucesso, a efetivação será realizada após a confirmação do pagamentos das taxas.", "success");
                    navigate("/Home");
                }, 1);
            } else {
                window.swal("Ação cancelada", "Envio de ficha cancelado", "error");
            }
         });
    }


    const _HandleReOpen = async() => {
        await Req("api/Inscricao/OpenFicha", "POST", {entidade_id: search.entidade_id, evento_id: etapa?.evento_id, etapa_id: Id}, user.token, 0*24*60*1000).then((data => {
            if(data.toString() === "loaded") {
                window.location.href = window.location.href + "?" + (new Date().getTime());
            }
        })).catch(err => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (OPN001)", "error");
        });
    }
    //#region Handlers


    return (
        <div className="main-content Inscricao">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card h-100 mb-lg-0">
                                                <div className="card-body">
                                                    <h3 className=""><b>{etapa?.evento}</b></h3>
                                                    <h5 className="card-title mb-4" style={{color:'#CC0000'}}>{etapa?.nome} ({etapa?.inicio===etapa?.fim ? (<>{moment(etapa?.inicio).format('DD/MM/YYYY')}</>):(<>{moment(etapa?.inicio).format('DD/MM/YYYY')} a {moment(etapa?.fim).format('DD/MM/YYYY')}</>)}) </h5>
                                                    <div className="_text-muted">
                                                        <hr />
                                                        <h4>Inscrições abertas de {moment(etapa?.inicio_insc).format('DD/MM/YYYY')} até {moment(etapa?.fim_insc).format('DD/MM/YYYY')}</h4>
                                                        <h5>Local: <b>{etapa?.local}</b></h5> 
                                                        <br />
                                                        <p style={{whiteSpace: "pre-wrap"}}>{etapa?.descricao}</p>
                                                        
                                                        <hr />
                                                        <br />
                                                        <p><h5>Provas para modalidade: <b style={{color:'#CC0000'}}>{etapa?.modalidade}</b> para <span style={{color:'#776acf'}}>{deficiencias.find(d => d.id === etapa?.atletas_tipos)?.name}</span></h5></p>

                                                  
                                                        <ul className="ps-3">
                                                            {
                                                                Array.isArray(etapa?.provas) && etapa?.provas.length > 0 && etapa?.provas.map((item, i) => (
                                                                    <li key={'prova'+i} className="py-1 provas-li"><h5>{selprovas?.length > 0 && (selprovas.find(p => p.prova_id === item.prova_id)?.estilo + " " + selprovas?.find(p => p.prova_id === item.prova_id)?.nome + (item?.niveis!==""?" (":"") + item?.niveis + (item?.niveis!==""?") ":""))}</h5></li>
                                                                ))
                                                            }
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="row">
                                      

                                        <div className="col-xl-4 col-sm-6">
                                            <div className="card">
                                                <div className="card-body" style={{height:'86px'}}>
                                                    <div className="d-flex align-items-center">
                                                        {user?.nivel !== 3 && (
                                                            <select id="entidade_id" className="form-select" value={search.entidade_id} onChange={_HandleChange} _disabled={timeEnded}>
                                                                {
                                                                    Array.isArray(entidades) && entidades?.length > 0 && entidades.map((item, i) => (
                                                                        <option key={'entidade'+item.entidade_id} value={item.entidade_id}>{item.nome}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        )}

                                                        {user?.nivel === 3 && (  
                                                            <>
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar">
                                                                    <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                                                        <i className="uil uil-archway"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <ReactTooltip key={"tooltip_entidade"} anchorId={"tooltip_entidade"} variant="info" place="top" content={Array.isArray(entidades) && entidades?.length > 0 && (entidades[0].nome)} />
                                                                <h5 className="font-size-16 mb-1 text-truncate" id="tooltip_entidade">{Array.isArray(entidades) && entidades?.length > 0 && (entidades[0].nome)}</h5>
                                                                <p className="mb-0 text-truncate text-muted">Entidade escolhida</p>
                                                            </div>
                                                            </>
                                                        
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-sm-6">
                                            <div className="card">
                                                <div className="card-body" style={{height:'86px'}}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                                                    <i className="uil uil-users-alt"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="font-size-16 mb-1">{atletasCount}</h5>
                                                            <p className="mb-0 text-truncate text-muted">Total de inscrições</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-sm-6">
                                            <div className="card">
                                                <div className="card-body" style={{height:'86px'}}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar">
                                                                <div className="avatar-title bg-soft-primary rounded font-size-20 text-primary">
                                                                    <i className="uil uil-clock-eight"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <h5 className="font-size-16 mb-1">{Currency(total)}</h5>
                                                            <p className="mb-0 text-truncate text-muted">Valor total do evento</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    {search?.entidade_id !== "" && (                     
                                    <div>
                                        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#timeA" role="tab">
                                                    <span className="d-none d-sm-block font-size-16"> <i className="mdi mdi-translate font-size-20"></i> &nbsp; Inscrever Time A &nbsp;  </span> 
                                                </a>
                                            </li>

                                           {/*<li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#timeB" role="tab">
                                                    <span className="d-none d-sm-block font-size-16"> <i className="mdi mdi-text-to-speech-off font-size-20"></i> &nbsp; Inscrever Time B &nbsp;  </span> 
                                                </a>
                                            </li>*/}
                                        </ul>

                                        <div className="tab-content text-muted">
                                            <div className="tab-pane active" id="timeA" role="tabpanel">
                                                <br />
                                                <Time EntidadeId={search?.entidade_id} EventoId={etapa?.evento_id} EtapaId={Id} TimeId={0} EditableMode={!timeEnded} Modalidade={etapa?.modalidade} Provas={etapa?.provas} OnAtletasChange={_HandleAtletasChange} OnFichaLoad={_HandleFichaLoad} />
                                            </div>


                                            {/*<div className="tab-pane" id="timeB" role="tabpanel">
                                                <br />
                                                <Time EntidadeId={search?.entidade_id} EventoId={etapa?.evento_id} EtapaId={Id} TimeId={1} EditableMode={!timeEnded} Modalidade={etapa?.modalidade} Provas={etapa?.provas} OnAtletasChange={_HandleAtletasChange} OnFichaLoad={_HandleFichaLoad} />
                                            </div>*/}
                                        </div>
                                    </div>  
                                    )}             
                                                            
                         
                                    {/*sendFichaEnable?1:0} / {fichaStatus} / {timeEnded?1:0*/}
                                    { sendFichaEnable && fichaStatus === 0 && !timeEnded && (
                                    <center>
                                        <br />
                                        <small>A efetivação das incrições será feita após o reconhimento integral dos valores respectivos</small><br /><br />
                                        <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="button" onClick={_HandleSendClose}>Enviar Ficha para esta etapa</button>
                                        <br />
                                    </center>     
                                    )}

                                    { fichaStatus === 1 && /*user?.nivel === 0 &&*/ (
                                    <center>
                                        <br />
                                        <small><b>IMPORTANTE!</b> Após reabrir uma ficha para edição ela deve ser reenviada efetivação das incrições</small><br /><br />
                                        <button id="btn_svo" className="btn btn-primary btn-lg btn-rounded mb-2" type="button" onClick={_HandleReOpen}> &nbsp; &nbsp; Reabrir Ficha  &nbsp; &nbsp; </button>
                                        <br />
                                    </center>     
                                    )}

                                   
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                        <div className="text-center">
                                            <h5 className="font-size-17">{timeEnded?'Inscrições Encerradas':'Inscrições Abertas'}</h5>
                                            <div className="mt-3 pt-1">
                                                <div className="d-flex justify-content-between">
                                                    <p className="text-muted font-size-13 mb-1">{moment(etapa?.inicio_insc).format('DD/MM/YYYY')}</p>
                                                    <p className="text-muted font-size-13 mb-1">{moment(etapa?.fim_insc).format('DD/MM/YYYY')}</p>
                                                </div>
                                                <div className="progress animated-progess custom-progress">
                                                    <div className={'progress-bar bg-gradient bg-'+(percent<30?'info':(percent<80?'warning':'danger'))} role="progressbar" style={{width: percent + '%'}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            {faltam>0 && !timeEnded && (<p className="mb-0 text-muted"><i className="mdi mdi-clock me-1"></i> Falta {faltam} {faltam===1?"dia":"dias"} para acabar as inscrições</p>)}
                                            {faltam===0 && !timeEnded && (<p className="mb-0 text-muted"><i className="mdi mdi-clock me-1"></i> Hoje é o último dia de inscrição</p>)}
                                            {timeEnded && (<p className="mb-0 text-muted"><i className="mdi mdi-clock me-1"></i> Etapa não está disponível</p>)}
                                        </div>
                                           

                                        

                                        <div className="mt-4 pt-3">
                                            <div className="alert alert-danger fade show px-3 mb-0" role="alert">
                                                
                                                <div className="mb-3">
                                                    <i className="uil uil-users-alt h1 text-danger"></i>
                                                </div>

                                                <div>
                                                    <h5 className="text-danger">Importante!</h5>
                                                    <p>Apenas atletas e staffs com cadastro regularizado podem ser inscritos nos eventos, acesse o cadastro e regularize-os</p>
                                                    <div className="text-center">
                                                        <button type="button" className="btn btn-link text-decoration-none text-danger" onClick={_HandleCadastro}>Cadastro de atletas e staff <i className="mdi mdi-arrow-right"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <br />
                                    
                                       
                                        <div className="card border shadow-none mb-2">
                                            <a href="#" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex align-items-center">
                                                        <div className="avatar align-self-center me-3">
                                                            <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                                                <i className="mdi mdi-currency-usd"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                            <h5 className="font-size-15 text-truncate mb-1">{Currency(etapa?.valor_afiliado)}</h5>
                                                            <p className="text-muted text-truncate mb-0">Valor de inscrição para filiado</p>
                                                        </div>

                                                   
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="card border shadow-none mb-2">
                                            <a href="javascript: void(0);" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex align-items-center">
                                                        <div className="avatar align-self-center me-3">
                                                            <div className="avatar-title rounded bg-soft-warning text-warning font-size-24">
                                                            <i className="mdi mdi-currency-usd"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                        <h5 className="font-size-15 text-truncate mb-1">{Currency(etapa?.valor_nafiliado)}</h5>
                                                            <p className="text-muted text-truncate mb-0">Valor de inscrição para não filiado</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}