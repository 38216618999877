/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Evento.css';



export function Evento() {
    const component_name = "admin/relatorio_evento";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [atletas, setAtletas] = useState(0);
    const [staff, setStaff] = useState(0);
    
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { EventoId} = useParams();

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    if(user.nivel === 3) {
                        navigate("/Home");
                    } else {
                        Init();
                    } 
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        LoadEtapas();
    }
    //#endregion Init


    //#region Loaders
    const LoadEtapas = async() => {
        await Req("api/Reports/Etapas", "POST", {evento_id: EventoId}, user.token, 0).then(data => { 
            let _data = [];
            let _atletas = 0;
            let _staff = 0;
            
            data.map((item, i) => {
                let _index = _data.findIndex(l => l.entidade === item.entidade);
                if(_index === -1) {
                    _data.push({evento_id: item.evento_id, entidade_id: item.entidade_id, entidade: item.entidade, filiado: item.filiado, emails: item.emails, uf: item.uf, cidade: item.cidade, etapas: [{etapa_id: item.etapa_id, etapa: item.etapa, atletas: item.atletas, staff: item.staff}] });
                } else {
                    _data[_index].etapas.push({etapa_id: item.etapa_id, etapa: item.etapa, atletas: item.atletas, staff: item.staff});
                }
                _atletas += item.atletas;
                _staff += item.staff;
            });

            setAtletas(_atletas);
            setStaff(_staff);
            setList(_data); 
        });
    }
    //#endregion Loaders


    const _HandleEtapaReport = (event, item) => {
        navigate("/PrintService/Participantes/"+item.entidade_id+"/"+item.evento_id);
    }


    const _HandlePlanilha = () => {
        navigate("/PrintService/PlanilhaEvento/"+EventoId);
    }
    //#endregion Handlers




    return (
        <div className="main-content ReportEventos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Entidades Filiadas</h4>

                                    <i className={'far fa-file-excel pointer'} onClick={() => _HandlePlanilha()}></i>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">

                                            <table className="table mb-0">
                 
                                                <tbody>
                                                {list?.filter(e => { return e.filiado === 1}).map((item, i) => (
                                                    <>
                                                        <tr className="th_evento">
                                                            <td colSpan={2}>
                                                                <span><b>{item.entidade} - {item.cidade}-{item.uf}</b></span> &nbsp;
                                                                <span>({item.emails})</span>
                                                            </td>
                                                            <td>
                                                                <i className={'fa fa-print pointer'} onClick={(e) => _HandleEtapaReport(e, item)}></i>
                                                            </td>
                                                        </tr>

                                                        <tr className="th_etapa">
                                                            <td>
                                                                Nome da Etapa
                                                            </td>
                                                            <td>
                                                                Staff
                                                            </td>
                                                            <td>
                                                                Atletas
                                                            </td>
                                                        </tr>


                                                        {item?.etapas.map((etapa, j) => (
                                                            <tr>
                                                                <td>
                                                                    {etapa.etapa}
                                                                </td>
                                                                <td>
                                                                    {etapa.staff}
                                                                </td>
                                                                <td>
                                                                    {etapa.atletas}
                                                                </td>
                                                            </tr>
                                                        ))}


                                                        <tr className="th_total">
                                                            <td>
                                                                
                                                            </td>
                                                            <td>
                                                                Total
                                                            </td>
                                                            <td>
                                                                {(item?.etapas.reduce((p, c) => p + c.atletas, 0)||0)+(item?.etapas.reduce((p, c) => p + c.staff, 0)||0)}
                                                            </td>
                                                        </tr>
                                                        
                                                    </>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                            <br />               
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Entidades Não Filiadas</h4>

                                
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">

                                            <table className="table mb-0">
                 
                                                <tbody>
                                                {list?.filter(e => { return e.filiado === 0}).map((item, i) => (
                                                   <>
                                                   <tr className="th_evento">
                                                       <td colSpan={2}>
                                                            <span><b>{item.entidade} - {item.cidade}-{item.uf}</b></span> &nbsp;
                                                           <span>({item.emails})</span>
                                                       </td>
                                                       <td>
                                                           <i className={'fa fa-print pointer'} onClick={(e) => _HandleEtapaReport(e, item)}></i>
                                                       </td>
                                                   </tr>

                                                   <tr className="th_etapa">
                                                       <td>
                                                           Nome da Etapa
                                                       </td>
                                                       <td>
                                                           Staff
                                                       </td>
                                                       <td>
                                                           Atletas
                                                       </td>
                                                   </tr>


                                                   {item?.etapas.map((etapa, j) => (
                                                       <tr>
                                                           <td>
                                                               {etapa.etapa}
                                                           </td>
                                                           <td>
                                                               {etapa.staff}
                                                           </td>
                                                           <td>
                                                               {etapa.atletas}
                                                           </td>
                                                       </tr>
                                                   ))}


                                                   <tr className="th_total">
                                                       <td>
                                                           
                                                       </td>
                                                       <td>
                                                           Total
                                                       </td>
                                                       <td>
                                                           {(item?.etapas.reduce((p, c) => p + c.atletas, 0)||0)+(item?.etapas.reduce((p, c) => p + c.staff, 0)||0)}
                                                       </td>
                                                   </tr>
                                                   
                                                </>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                            <br />           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
