/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Provas.css';



let provasData = [];
let provasTipo = "";


export function Provas() {
    const component_name = "admin/print_provas";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [etapa, setEtapa] = useState({});
    const [provas, setProvas] = useState([]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { EtapaId } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        await LoadProvas();
        await LoadEtapa();
    }
    //#endregion Init



    //#region Loaders
    const LoadProvas = async() => {
        let _provas = await Req("/assets/json/provas.json", "GET", "", "", 0*24*60*1000);
        if(_provas.toString() !== "error") {
            provasData = _provas;
            //console.log(provasData);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
        }

        return true;
    }


    const LoadEtapa = async() => {
        setPageLoading(true);
        await Req("api/Reports/AtletasProvas", "POST", {etapa_id: EtapaId}, user.token, 0).then(data => { 
            setPageLoading(false);

            setEtapa(data);

            let arr = [];
            if(data?.evento?.modalidade?.toString().toLowerCase().indexOf('atletismo') > -1) arr = provasData['atletismo'];
            if(data?.evento?.modalidade?.toString().toLowerCase().indexOf('natação') > -1) arr = provasData['natacao'];
            //console.log(arr);
            if(arr.length > 0) setProvas(arr);

            setTimeout(() => {
                //window.print();
            }, 1000);
        }).catch(() => { 
            setPageLoading(false);
        });
    }
    
    //#endregion Loaders





    return (
        <>
            <div id="PrintProvas" align="center" style={{width:'100%'}}>
                <br />
                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <tbody>
                        <tr>
                            <td align="center" style={{fontFamily: 'Verdana, Arial', fontSize:'10pt', padding:'15px 8px 8px 8px'}}>
                                <img src="/assets/images/logo_print.jpg" height="60px" align="left" style={{margin: '0px 20px 0px 3px'}} /> 
                                <b>{etapa?.evento?.nome}
                                <br/>{etapa?.evento?.cidade}/{etapa?.evento?.uf} - De {moment(etapa?.evento?.inicio).format('DD/MM/YYYY')} a {moment(etapa?.evento?.fim).format('DD/MM/YYYY')} </b><br />
                                <h5>{etapa?.evento?.local}</h5> 
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />


                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <thead>
                        <tr>
                            <th colSpan={4}>
                                <center><b>LISTA DE ATLETAS ORDENADA POR PROVAS</b></center>
                            </th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {Array.isArray(etapa?.provas) && etapa?.provas.length > 0 && etapa?.provas.map((prova, i) => (
                            <React.Fragment key={'ficha'+i}>
                                { Array.isArray(prova?.atletas) && prova?.atletas.length > 0 && (
                                <tr>
                                    <td colSpan={4}>
                                        <span><b>Prova {provas.find(p => p.prova_id === prova.prova_id)?.nome}</b></span> <br /> {prova.niveis}
                                    </td> 
                                </tr>)}

                                {
                                    Array.isArray(prova?.atletas) && prova?.atletas.length > 0 && prova?.atletas.map(atleta => (
                                        <tr>
                                            <td>{atleta?.nome}</td>
                                            <td>{atleta?.entidade}</td>
                                            <td>{atleta?.niveis}</td>
                                            <td>{atleta?.filiado===1?'F':'NF'}</td>
                                        </tr>
                                    ))
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

               
            </div>
        </>
    )
}