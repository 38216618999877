/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Participantes.css';


export function Participantes() {
    const component_name = "admin/print_participantes";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [modalidades, setModalidades] = useState([]);
    const [staff, setStaff] = useState([]);
    const [entidade, setEntidade] = useState({});
    const [evento, setEvento] = useState({});
    
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { EntidadeId, EventoId } = useParams();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        LoadParticipantes()
    }
    //#endregion Init


     //#region Loaders
     const LoadParticipantes = async() => {
        await Req("api/Reports/Participantes", "POST", {entidade_id: EntidadeId, evento_id: EventoId}, user.token, 0).then(data => { 
            setEntidade(data.entidade);
            setEvento(data.evento);
            try {
                    setStaff([...new Map(data.staff.map(item => [item['atleta_id'], item])).values()]);
            } catch(e) { }

            let _modalidades = [];
            data.atletas.map((item, i) => {
                let _index = _modalidades.findIndex(l => l.modalidade_id === item.modalidade_id);
                if(_index === -1) {
                    _modalidades.push({modalidade_id: item.modalidade_id, modalidade: item.modalidade, atletas: [{atleta_id: item.atleta_id, atleta: item.atleta, cpf: item.cpf, rg: item.rg}]});
                } else {
                    _modalidades[_index].atletas.push({atleta_id: item.atleta_id, atleta: item.atleta, cpf: item.cpf, rg: item.rg});
                }
            });

            setModalidades(_modalidades); 

            setTimeout(() => {
                window.print();
            }, 1000);
        });
    }
    //#endregion Loaders


    return (
        <>
           <div id="PrintParticipantes" align="center" style={{width:'100%'}}>
                <br />
                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <tbody>
                        <tr>
                            <td align="center" style={{fontFamily: 'Verdana, Arial', fontSize:'10pt', padding:'15px 8px 8px 8px'}}>
                                <img src="/assets/images/logo_print.jpg" height="60px" align="left" style={{margin: '0px 20px 0px 3px'}} /> 
                                <b>{evento?.nome}
                                <br/>{evento?.cidade}/{evento?.uf} - Em: {moment(evento?.inicio).format('DD/MM/YYYY')}</b><br />
                                <h5>LISTA DE INSCRITOS</h5> 
                            </td>
                        </tr>
                    </tbody>
                </table>
                

                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <tbody>
                        <tr>
                            <td align="center" style={{fontFamily: 'Verdana, Arial', fontSize:'8pt'}}>
                                <b style={{fontFamily: 'Verdana, Arial', fontSize:'10pt'}}>{entidade?.abrev} - {entidade?.nome}</b>
                                <br />{entidade?.endereco}, {entidade?.numero}, {entidade?.cep} - {entidade?.cidade}-{entidade?.uf}
                                <br />Telefone: {entidade?.telefone1}
                                <br />E-mails: {entidade?.emails}
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                
                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <tbody>
                        <td className="texto1" width="50">Num</td>
                        <td className="texto1">Nome</td>
                        <td className="texto1" width="160">CPF</td>
                        <td className="texto1" width="160">RG</td>
                    </tbody>
                </table>
                

                <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                    <tbody>
                        <tr>
                            <td class="texto1" width="50" colspan="4">
                                <b>Staffs / Técnicos</b>
                            </td>
                        </tr> 

                        {
                            Array.isArray(staff) && staff.length > 0 && staff.map((item, i) => (
                                <tr key={'staff'+i}>
                                    <td className="texto1" width="50">{item?.numero}</td>
                                    <td className="texto1" style={{textAlign:'left', textTransform:'uppercase'}}>{item?.atleta}</td>
                                    <td className="texto1" width="160">{item?.cpf}</td>
                                    <td className="texto1" width="160">{item?.rg}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                
                { 
                    Array.isArray(modalidades) && modalidades?.length > 0 && modalidades?.map((modalidade, j) => (
                        <table border="2" cellspacing="0" style={{margin: '5px;', width:'820px'}}>
                            <tbody>
                                <tr>
                                    <td class="texto1" width="50" colspan="4">
                                        <b>{modalidade?.modalidade}</b>
                                    </td>
                                </tr> 

                                {
                                    Array.isArray(modalidade?.atletas) && modalidade?.atletas?.length > 0 && modalidade.atletas.map((item, i) => (
                                        <tr key={'staff'+i}>
                                            <td className="texto1" width="50">{item?.atleta_id}</td>
                                            <td className="texto1" style={{textAlign:'left', textTransform:'uppercase'}}>{item?.atleta}</td>
                                            <td className="texto1" width="160">{item?.cpf}</td>
                                            <td className="texto1" width="160">{item?.rg}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    ))
                }

                
           </div>
         </>
    )
}