/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';


let current_element;
let cliente_auto_fill = false;


export function Cadastro() {
    const component_name = "admin/atletas_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValidaded, setIsFormValidaded] = useState(true);
    const [atleta, setAtleta] = useState("");
    const [cpf, setCpf] = useState("");

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { Id } = useParams();



    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init


    //#region Loaders
    const LoadEntidades = (word = "") => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/AtletasTransferencia/Entidades", "POST", {word: word/*, entidade_id: (user.nivel===3?user.entidade_id:'')*/}, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                data.unshift({id: 0, name: "Nenhuma entidade associada"})
                await _formUserRef.current.Select("entidade_destino", data);
                await _formUserRef.current.Select("entidade_origem", data);

                if(user.nivel===3) {
                    setTimeout(async() => {
                        try {
                            await _formUserRef.current?.Value("entidade_destino", user.entidade_id);
                        } catch(e) { }
                    }, 100)
                }
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATTRA01)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATTRA002)", "error");
        });
    }
    //#endregion Loaders


    //#region Form Handlers
    const atleta_id = async(value, args) => {
        if(args) {
            if(args.last_transfer === "") {
                await _formUserRef.current.Value("entidade_origem", args.entidade_id);
            } else if(args.last_transfer !== "" && !Id) {
                window.swal("Alerta", "Esse atleta já está em processo de transferência", "error");
                await _formUserRef.current.Value("entidade_origem", "");
                await _formUserRef.current.Value("atleta_id", "", "");
            }
        }
    }
    
    const OnFormLoading = async() => {
        cliente_auto_fill = false;
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
        
        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Id) {
                let unidade_data = await new Request().Run("api/AtletasTransferencia/Load?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.tranferencia_id !== null) {
                    await _formUserRef.current.Load(unidade_data);
                }
            }

            if(user.nivel===3) {
                await _formUserRef.current.DisableFields(Id?["atleta_id", "entidade_destino", "entidade_origem", "status"]:["entidade_destino","entidade_origem", "status"], false);
            } else {
                await _formUserRef.current.DisableFields(Id?["atleta_id", "entidade_destino", "entidade_origem", "status"]:["entidade_origem", "status"], false);
            }

     

            setTimeout(()=> {
                LoadEntidades();
            }, 100);

            setTimeout(()=> {
                cliente_auto_fill = true;
            }, 500);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value, args) => {
        try {
            eval(func)(value, args);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();
       
        if(validade) {
            if(!_formUserRef.current.GetValue("entidade_destino")?.id || !_formUserRef.current.GetValue("entidade_origem")?.id) {
                window.swal("Alerta", "A entidade destino ou origem incorretas!", "error");
                return;
            } else if(_formUserRef.current.GetValue("entidade_destino")?.id === _formUserRef.current.GetValue("entidade_origem")?.id) {
                window.swal("Alerta", "A entidade destino não pode ser a mesma de origem","error");
                return;
            } else if(_formUserRef.current.GetValue("entidade_destino")?.id === 0 || _formUserRef.current.GetValue("entidade_origem")?.id === 0) {
                window.swal("Alerta", "É necessário escolher a entidade de destino!","error");
                return;
            } else {
                setPageLoading(true);
                let result = await _formUserRef.current.Post("api/AtletasTransferencia/Save", {transferencia_id: Id}, user.token);
                setPageLoading(false);
    
                if(result === "error") {
                    window.swal("Alerta", "Preencha todos campos obrigatórios (TRAPST001)", "error");
                    return;
                } else if(result === "exists") {
                    window.swal("Alerta", "Transferência já  registrada", "error");
                    return;
                }
    
                window.scrollTo({top: 0, behavior: 'smooth'});
                navigate("/AtletasTransferencia");
            }
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios (TRAPST002)", "error");
        }

    }
    //#endregion Handlers
     

    //#region Funtions
  
    //#endregion Functions

    return(
        <div className="main-content AtletasTransferencia">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de transferência &nbsp;&nbsp; <span className={atleta===''?'hide':''} style={{color:'#8dc572'}}> &nbsp;&nbsp; <b>Atleta:</b> </span> <span style={{color:'#777777'}}>{atleta}</span> <span className={cpf===''?'hide':''} style={{color:'#8dc572'}}> &nbsp;&nbsp; <b>CPF:</b> </span> <span style={{color:'#777777'}}>{cpf}</span></h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('transferencia', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'transferencia'} url={'/assets/forms/transferencia.json'} execRef={Exec} token={user.token}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <center>
                                                    <hr />
                                                    {!Id && (<button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>)}
                 
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>

        </div>
    )
}