/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './index.css';


export default function Lista() {
    const component_name = "admin/eventos_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [estados, setEstados] = useState([]);
    const [search, setSearch] = useState({ word: "", uf: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    if(user.nivel === 3) {
                        navigate("/Home");
                    } else {
                        Init();
                    } 
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
     const Init = () => {
        LoadUfs();
        _HandlerSearchChange();
     }
    //#endregion Init


    //#region Loaders
    const LoadUfs = () => {
        Promise.resolve(new Request().Run("/assets/json/estados.json", "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            setEstados(data);

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Eventos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word, uf: searchRef.current.uf }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Eventos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word, uf: searchRef.current.uf}, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleEdit = (e, id) => {
        navigate("/Evento/"+id);
    }


    const _HandleReport = (e, id) => {
        navigate("/Relatorios/Evento/"+id);
    }


    const _HandlePlanilha = (item) => {
        navigate("/PrintService/PlanilhaEvento/"+item.evento_id);
    }


    const _HandleFicha = (item) => {
        navigate("/Relatorios/Fichas/"+item.evento_id);
    }


  


    const _HandleAddEvento = () => {
        navigate("/Evento/");
    }


    const _HandleStatus = (item) => {
        Promise.resolve(new Request().Run("api/Eventos/Status", "POST", { evento_id: item.evento_id, status: item.status}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.evento_id === item.entidade_id).status= data;
            setList(_list);
        });
    }
    //#endregion Handlers



    return (
        <div className="main-content Eventos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Eventos</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                            <div className="align-right">
                                                <button type="button" className={'btn btn-primary ' + (user.nivel>1?'hide':'')} onClick={_HandleAddEvento}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                                </button>
                                            </div>

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

             

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="uf" className="form-select" value={search.uf} onChange={_HandleChange}>
                                                        <option value="">Todo Brasil</option>
                                                        {
                                                            Array.isArray(estados) && estados?.length > 0 && estados.map((item, i) => (
                                                                <option key={'uf'+item.id} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>Local</th>
                                                        <th>Cidade</th>
                                                        <th>Inicio</th>
                                                        <th>Fim</th>
                                                        <th style={{width:'70px'}}>Status</th>
                                                        <th style={{width:'125px'}}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td>{item.nome}</td>
                                                        <td>{item.local}</td>
                                                        <td>{item.cidade}-{item.uf}</td>
                                                        <td>{moment(item.inicio).format('DD/MM/YYYY')}</td>
                                                        <td>{moment(item.fim).format('DD/MM/YYYY')}</td>
                                                        <td style={{padding:'0 0 0 0'}}>
                                                            <select className="form-control inputfull">
                                                                <option value={0}>Agendado</option>
                                                                <option value={1}>Reagendado</option>
                                                                <option value={2}>Concluído</option>
                                                                <option value={3}>Cancelado</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <ReactTooltip key={"tooltip_a"+i} anchorId={"td_ia"+i} variant="info" place="top" content={'Editar ficha'} />
                                                            <i id={"td_ia"+i} className="fa fa-edit" onClick={(e) => _HandleEdit(e, item.evento_id)}></i>
                                                            &nbsp;&nbsp;&nbsp;

                                                            
                                                            <ReactTooltip key={"tooltip_a"+i} anchorId={"td_ib"+i} variant="info" place="top" content={'Participantes'} />
                                                            <i id={"td_ib"+i} className={'fa fa-print'} onClick={(e) => _HandleReport(e, item.evento_id)}></i>
                                                            &nbsp;&nbsp;&nbsp;

                                                            <ReactTooltip key={"tooltip_a"+i} anchorId={"td_ic"+i} variant="info" place="top" content={'Planilhas'} />
                                                            <i id={"td_ic"+i} className={'far fa-file-excel'} onClick={() => _HandlePlanilha(item)}></i>
                                                            &nbsp;&nbsp;&nbsp;

                                                            <ReactTooltip key={"tooltip_a"+i} anchorId={"td_id"+i} variant="info" place="top" content={'Fichas'} />
                                                            <i id={"td_id"+i} className={'fas fa-book'} onClick={() => _HandleFicha(item)}></i>

                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}