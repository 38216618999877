/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Inscricoes.css';


export default function Inscricoes() {
    const component_name = "admin/inscricoes_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [estados, setEstados] = useState([]);
    const [search, setSearch] = useState({ word: "", uf: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();


     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        _HandlerSearchChange();
    }
    //#endregion Init



    //#region Handlers
    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Home/Etapas/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Home/Etapas/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }



    const _HandleClick = (item) => {
        navigate("/Inscricao/"+item.etapa_id);
    }
    //#endregion Handlers



    return (
        <div className="card">
            <div className="card-body pb-3">
                <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-2">Eventos com inscrições abertas</h5>
                    </div>
                    <div className="flex-shrink-0">
            
                    </div>
                </div>

                <div id="scroll_inscricoes" className="">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={_fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Carregando...</b>
                            </p>
                        }
                        scrollableTarget="scroll_inscricoes"
                        
                        endMessage={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Não há mais dados</b>
                            </p>
                        }>    


                        {list.map((item, i) => (
                            <div key={'inscri'+i} className="social-box row align-items-center border-bottom pt-0 g-0" style={{cursor:'pointer'}} onClick={() => _HandleClick(item)}>
                                <div className="col-12 col-sm-9">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <img src={'/assets/images/modalidades/'+item.modalidade_id+'.png'} alt=""/>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="font-size-15 mb-1 text-truncate">{item.evento}</h5>
                                            <p className="text-muted mb-0">{item.nome}</p>
                                        </div>
                                    </div>
                                </div>
      
                                <div className="col-auto col-sm-3">
                                    <div className="mt-3 mt-md-0 text-end">
                                        <h5 className="font-size-13 mb-1 text-truncate">{item?.inscritos||0} inscritos</h5>
                                        <p className="text-muted mb-0">
                                            <span className="badge bg-success">Até {moment(item.fim_insc).format('DD/MM/YYYY')}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
        
    )

}