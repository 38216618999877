import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Request, Req } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';



let current_element;

export function Content() { 
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const tipos = [
        {tipo_id: 1, nome: "Calendário"},
        {tipo_id: 2, nome: "Boletins e Comunicados Oficiais"},
        {tipo_id: 3, nome: "Estatuto Social"},
        {tipo_id: 4, nome: "Atas"},
        {tipo_id: 6, nome: "Transparência Adm. / Financeiro"},
        {tipo_id: 7, nome: "Goalball"},
        {tipo_id: 8, nome: "Futebol"},
        {tipo_id: 9, nome: "Judô"},
        {tipo_id: 10, nome: "Atletismo"},
        {tipo_id: 11, nome: "Natação"},
        {tipo_id: 12, nome: "Xadrez"}
    ];


     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadList();
    }
    //#endregion Init


    //#region Loaders
    const LoadList = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Transparencia/List/0/0/0", "POST", "", "", 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                let old_tipo_id = "";
                let _list = data.list
                _list.map((item, i) => {
                    if(item.tipo_id !== old_tipo_id) {
                        old_tipo_id = item.tipo_id;
                        item.tipo = tipos.find(t => t.tipo_id === item.tipo_id).nome;
                    } else {
                        item.tipo = "";
                    }

                    let _dt = item.date_doc.split('-');
                    item.date_doc = _dt[1] + "/" +_dt[0]
                });
                setList(_list );
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRALST001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (TRALST002)", "error");
        });
    }
    //#endregion Loaders


    return (
        <div className="ShareTransparencia">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                
                                <div className="card-body pb-2">
                                    <div className='row'>
                                        
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Arquivo</th>
                                                            <th>Data</th>
                                                            <th>Download</th>
                                                        
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                    
                                                    {list.map((item, i) => (
                                                        <>
                                                            {
                                                                item.tipo !== '' &&  (
                                                                    <tr className="tr_head">
                                                                        <td colSpan={5}><b>{item.tipo}</b></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            
                                                            
                                                            <tr key={'tr' + i} className="pointer">
                                                                <td>{item.nome}</td>
                                                                <td>{item.date_doc}</td>
                                                                <td><a href={'../storage/docs/'+item.doc} target="_blank" rel="noreferrer">Download</a></td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                    
                                                    </tbody>
                                                </table>
                                                <br /> <br />                     
                                            </div>
                                       

                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>


                        
                    </div>
                </div>
            </div>
        </div>

    )
}