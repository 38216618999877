/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Fichas.css';



export function Fichas() {
    const component_name = "admin/relatorio_fichas";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [atletas, setAtletas] = useState(0);
    const [staff, setStaff] = useState(0);
    
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { EventoId } = useParams();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    if(user.nivel === 3) {
                        navigate("/Home");
                    } else {
                        Init();
                    } 
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        LoadFichas();
    }
    //#endregion Init



    //#region Loaders
    const LoadFichas = async() => {
        await Req("api/Reports/FichasEntidades", "POST", {evento_id: EventoId}, user.token, 0).then(data => { 
            let _data = [];
            
            data.map((item, i) => {
                let _index = _data.findIndex(l => l.entidade === item.entidade);
                if(_index === -1) {
                    _data.push({evento_id: item.evento_id, entidade_id: item.entidade_id, entidade: item.entidade, filiado: item.filiado, email: item.email, uf: item.uf, cidade: item.cidade, etapas: [{etapa_id: item.etapa_id, etapa: item.etapa, status: item.status, inscritos: item.inscritos}] });
                } else {
                    _data[_index].etapas.push({etapa_id: item.etapa_id, etapa: item.etapa, status: item.status, inscritos: item.inscritos});
                }
            });

            setList(_data); 
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandlePrintFicha = (item, etapa) => {
        navigate("/PrintService/Fichas/"+item.entidade_id+"/"+etapa.etapa_id);
    }
    //#endregion Handlers


    return (
        <div className="main-content ReportFichas">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Fichas de Entidades Filiadas</h4>

                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">

                                            <table className="table mb-0">
                 
                                                <tbody>
                                                {list?.filter(e => { return e.filiado === 1}).map((item, i) => (
                                                    <>
                                                    <tr className="th_entidade">
                                                        <td colSpan={4}>
                                                            <span><b>{item.entidade} - {item.cidade}-{item.uf}</b></span> &nbsp;
                                                            <span>({item.email})</span>
                                                        </td>

                                                    </tr>


                                                    {item?.etapas.map((etapa, j) => (
                                                        <tr>
                                                            <td>
                                                                {etapa.etapa}
                                                            </td>
                                                            <td>
                                                                Inscritos: {etapa.inscritos}
                                                            </td>
                                                            <td style={{width:'105px'}}>
                                                                {etapa.status === 1 && (<span style={{color:'green'}}>Enviado</span>)}
                                                                {etapa.status === 0 && (<span style={{color:'red'}}>Não enviado</span>)}
                                                            </td>
                                                            <td style={{width:'70px'}}>
                                                                {/*<a className='pointer' onClick={() => {_HandlePrintFicha(item, etapa)}}>Imprimir</a>*/}
                                                                <a href={'/PrintService/Fichas/'+item.entidade_id+'/'+etapa.etapa_id} className='pointer' target="_blank">Imprimir</a>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                    
                                                </>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                            <br />               
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Fichas de Entidades Não Filiadas</h4>

                                
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">

                                        <table className="table mb-0">
                
                                            <tbody>
                                            {list?.filter(e => { return e.filiado === 0}).map((item, i) => (
                                                <>
                                                <tr className="th_evento">
                                                    <td colSpan={2}>
                                                        <span><b>{item.entidade} - {item.cidade}-{item.uf}</b></span> &nbsp;
                                                        <span>({item.email})</span>
                                                    </td>

                                                </tr>


                                                {item?.etapas.map((etapa, j) => (
                                                    <tr>
                                                        <td>
                                                            {etapa.etapa}
                                                        </td>
                                                        <td>
                                                            Inscritos: {etapa.inscritos}
                                                        </td>
                                                        <td style={{width:'105px'}}>
                                                            {etapa.status === 1 && (<span style={{color:'green'}}>Enviado</span>)}
                                                            {etapa.status === 0 && (<span style={{color:'red'}}>Não enviado</span>)}
                                                        </td>
                                                        <td style={{width:'70px'}}>
                                                            {/*<a className='pointer' onClick={() => {_HandlePrintFicha(item, etapa)}}>Imprimir</a>*/}
                                                            <a href={'/PrintService/Fichas/'+item.entidade_id+'/'+etapa.etapa_id} className='pointer' target="_blank">Imprimir</a>
                                                        </td>
                                                    </tr>
                                                ))}

                                                
                                            </>
                                            ))}
                                            
                                            </tbody>
                                        </table>
                                        <br />     



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}