/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './index.css';


export default function Lista() {
    const component_name = "admin/fichas_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [entidades, setEntidades] = useState([]);
    const [list, setList] = useState([]);
    const [estados, setEstados] = useState([]);
    const [search, setSearch] = useState({ word: "", entidade_id: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", entidade_id: "" });

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    if(user.nivel === 3) {
                        navigate("/Home");
                    } else {
                        Init();
                    } 
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
         await LoadEntidades();
        _HandlerSearchChange();
    }
    //#endregion Init


    //#region Loaders
    const LoadEntidades = async() => {
        await Req("api/Fichas/Entidades", "POST", "", user.token, 0).then(data => { setEntidades(data); });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = { word: "", entidade_id: "" });
        searchRef.current = { word: "", entidade_id: "" };
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Fichas/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word, entidade_id: searchRef.current.entidade_id }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Fichas/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word, entidade_id: searchRef.current.entidade_id}, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleEtapa = (item) => {
        navigate("/Inscricao/"+item.etapa_id+"/"+item.entidade_id);
    }


    const _HandlePago = (item, pago) => {
        Promise.resolve(new Request().Run("api/Fichas/Pago", "POST", { entidade_id: item.entidade_id, evento_id: item.evento_id, etapa_id: item.etapa_id, pago: pago}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.etapa_id === item.etapa_id && u.entidade_id === item.entidade_id).pago = parseInt(data);
            setList(_list);
        });
    }
    //#endregion Handlers




    return (
        <div className="main-content Eventos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Fichas Submetidas</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

             

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="entidade_id" className="form-select" value={search.entidade_id} onChange={_HandleChange}>
                                                        <option value="">Todas Entidades</option>
                                                        {
                                                            Array.isArray(entidades) && entidades?.length > 0 && entidades.map((item, i) => (
                                                                <option key={'entid'+item.entidade_id} value={item.entidade_id}>{item.nome}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Entidade</th>
                                                        <th>Evento</th>
                                                        <th>Etapa</th>
                                                        <th>Inscritos</th>
                                                        <th>Valor</th>
                                                        <th>Pagamento</th>
                                                        <th style={{width:'50px'}}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td onClick={() => {_HandleEtapa(item)}}>{item.entidade}</td>
                                                        <td onClick={() => {_HandleEtapa(item)}}>{item.evento}</td>
                                                        <td onClick={() => {_HandleEtapa(item)}}>{item.etapa}</td>
                                                        <td onClick={() => {_HandleEtapa(item)}}>{item.inscritos}</td>
                                                        <td onClick={() => {_HandleEtapa(item)}}>{Currency(item.valor)}</td>
                                                        <td onClick={() => {_HandleEtapa(item)}}>{item.pago===1?'Pago':'Pendente'}</td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <a className="dropdown-item pointer" onClick={() => _HandlePago(item, 1)}>Marcar como pago</a>
                                                                    <a className="dropdown-item pointer" onClick={() => _HandlePago(item, 0)}>Pendente de pagamento</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}