/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { Currency } from '../../../scripts/StringUtils';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment/moment';
import './Planilha.css';



let index_max_modalidades = 0;
let max_modalidades = 0;

export function Planilha() {
    const component_name = "admin/print_planilha";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [evento, setEvento] = useState({});
    const [entidades, setEntidades] = useState([]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { EventoId } = useParams();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);


        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async() => {
        LoadPlanilha()
    }
    //#endregion Init



    //#region Loaders
    const LoadPlanilha = async() => {
        setPageLoading(true);
        await Req("api/Reports/Planilha", "POST", {etapa_id: EventoId}, user.token, 0).then(data => { 
            setPageLoading(false);
            setEvento(data.evento);



            let _entidades = [];
            data?.entidades.map((item, i) => {
                let _index = _entidades.findIndex(l => l.entidade_id === item.entidade_id);
                if(_index === -1) {
                    _entidades.push({entidade_id: item.entidade_id, entidade: item.entidade, cidade: item.cidade, uf: item.uf, modalidades: [ {etapa_id: item.etapa_id, modalidade_id: item.modalidade_id, modalidade: ShortModalidade(item.modalidade), staff: item.staff, atletas: item.atletas, atletas_dv: item.atletas_dv, atletas_df: item.atletas_df, atletas_di: item.atletas_di }]});
                } else {
                    _entidades[_index].modalidades.push({etapa_id: item.etapa_id, modalidade_id: item.modalidade_id, modalidade: ShortModalidade(item.modalidade), staff: item.staff, atletas: item.atletas, atletas_dv: item.atletas_dv, atletas_df: item.atletas_df, atletas_di: item.atletas_di });
                }
            });

    

            _entidades.map((item, i) => {
                if(item.modalidades.length > max_modalidades) {
                    max_modalidades = item.modalidades.length;
                    index_max_modalidades = i;
                }

                let _total_atletas = 0;
                let _total_atletas_dv = 0;
                let _total_atletas_df = 0;
                let _total_atletas_di = 0;
                let _total_staff = 0;

                let _mods = [];
                item.modalidades.map((mod, j) => {
                    let _index = _mods.findIndex(m => m.modalidade === mod.modalidade);
                    if(_index === -1) {

                        _mods.push({etapa_id: mod.etapa_id, modalidade_id: mod.modalidade_id, modalidade: mod.modalidade, staff: mod.staff, atletas: mod.atletas, atletas_dv: mod.atletas_dv, atletas_df: mod.atletas_df, atletas_di: mod.atletas_di })
                    } else {
                        _mods[_index].atletas += mod.atletas;
                        _mods[_index].atletas_dv += mod.atletas_dv;
                        _mods[_index].atletas_df += mod.atletas_df;
                        _mods[_index].atletas_di += mod.atletas_di;
                        _mods[_index].staff += mod.staff; 
                    }
                    _total_atletas += mod.atletas;
                    _total_atletas_dv += mod.atletas_dv;
                    _total_atletas_df += mod.atletas_df;
                    _total_atletas_di += mod.atletas_di;
                    _total_staff += mod.staff; 
                })


                item.modalidades = _mods;
                item.total = _total_atletas;
                item.visuais = _total_atletas_dv;
                item.fisicos = _total_atletas_df;
                item.intelectuais = _total_atletas_di;
                item.staff = _total_staff;
            })
            


            setEntidades(_entidades);

            setTimeout(() => {
                window.print();
            }, 1000);
        }).catch(() => { 
            setPageLoading(false);
        });
    }
    //#endregion Loaders


    //#region Functions
    const ShortModalidade = (mod) => {
        mod = mod.replace(' Feminino','').replace(' feminino','').replace(' FEMININO','');
        mod = mod.replace(' Masculino','').replace(' masculino','').replace(' MASCULINO','');
        return mod;
    }
    //#endregion Functions


    return (
        <>
            <div id="PrintPlanilha" align="center" style={{width:'100%'}}>
                <br />
                <table border="2" cellspacing="0" style={{margin: '5px;', width:'98%'}}>
                    <tbody>
                        <tr>
                            <td align="center" style={{fontFamily: 'Verdana, Arial', fontSize:'10pt', padding:'15px 8px 8px 8px'}}>
                                <img src="/assets/images/logo_print.jpg" height="60px" align="left" style={{margin: '0px 20px 0px 3px'}} /> 
                                <b>{evento?.etapa}
                                <br/>{evento?.cidade}/{evento?.uf} - Em: {moment(evento?.inicio).format('DD/MM/YYYY')}</b><br />
                                <h5>LISTA DE INSCRITOS</h5> 
                            </td>
                        </tr>
                    </tbody>
                </table>


                <table border="2" cellspacing="0" style={{margin: '5px;', width:'98%'}}>
                    <thead>
                        <tr>
                            <th>Equipe</th>
                            {
                                Array.isArray(entidades) && entidades?.length > 0 && entidades[index_max_modalidades]?.modalidades.map((item, i) => (
                                    <React.Fragment key={'enti'+i}>
                                        <th>{item.modalidade}</th>
                                        {
                                            item?.modalidade?.substr(0, 3).toLowerCase() === "atl" && (
                                                <>
                                                <th>Atl.DV</th>
                                                <th>Atl.DF</th>
                                                <th>Atl.DI</th>
                                                </>
                                            )
                                        }

                                        {
                                            item?.modalidade?.substr(0, 3).toLowerCase() === "nat" && (
                                                <>
                                                <th>Nat.DV</th>
                                                <th>Nat.DF</th>
                                                <th>Nat.DI</th>
                                                </>
                                            )
                                        }
                                     </React.Fragment>
                                ))
                            }
                            <th>T. Atletas</th>
                            <th>Visuais</th>
                            <th>Físicos</th>
                            <th>Intelectuais</th>
                            <th>Staff</th>
                            <th>Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            Array.isArray(entidades) && entidades?.length > 0 && entidades?.map((item, i) => (
                                <tr key={'tr_'+i}>
                                    <td>{item.entidade} / {item.cidade}-{item.uf}</td>
                                    {
                                        Array.isArray(entidades) && entidades?.length > 0 && entidades[index_max_modalidades]?.modalidades.map((item1, j) => (
                                            <React.Fragment key={'tmod'+i+'_'+j}>
                                            <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas}</td>
                                            {
                                                item1?.modalidade?.substr(0, 3).toLowerCase() === "atl" && (
                                                    <>
                                                    <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas_dv}</td>
                                                    <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas_df}</td>
                                                    <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas_di}</td>
                                                    </>
                                                )
                                            }

                                            {
                                                item1?.modalidade?.substr(0, 3).toLowerCase() === "nat" && (
                                                    <>
                                                    <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas_dv}</td>
                                                    <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas_df}</td>
                                                    <td>{item.modalidades.find(m => m.modalidade === item1.modalidade).atletas_di}</td>
                                                    </>
                                                )
                                            }
                                            </React.Fragment>
                                        ))
                                    }
                                    <td>{item?.total}</td>
                                    <td>{item?.visuais}</td>
                                    <td>{item?.fisicos}</td>
                                    <td>{item?.intelectuais}</td>
                                    <td>{item?.staff}</td>
                                    <td>{item?.total+item?.staff}</td>
                                </tr>
                            ))
                        }

                        <tr>
                            <td><b>TOTAIS</b></td>
                            {
                                Array.isArray(entidades) && entidades?.length > 0 && entidades[index_max_modalidades]?.modalidades.map((item, i) => (
                                    <React.Fragment key={'totais'+i}>
                                        <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas}, 0)}</th>
                                        {
                                            item?.modalidade?.substr(0, 3).toLowerCase() === "atl" && (
                                                <>
                                                <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas_dv}, 0)}</th>
                                                <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas_df}, 0)}</th>
                                                <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas_di}, 0)}</th>
                                                </>
                                            )
                                        }

                                        {
                                            item?.modalidade?.substr(0, 3).toLowerCase() === "nat" && (
                                                <>
                                                <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas_dv}, 0)}</th>
                                                <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas_df}, 0)}</th>
                                                <th>{entidades.reduce((a,b)=> { return a + b.modalidades[i].atletas_di}, 0)}</th>
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                ))
                            }
                            <td><b>{entidades.reduce((a,b)=> { return a + b.total}, 0)}</b></td>
                            <td><b>{entidades.reduce((a,b)=> { return a + b.visuais}, 0)}</b></td>
                            <td><b>{entidades.reduce((a,b)=> { return a + b.fisicos}, 0)}</b></td>
                            <td><b>{entidades.reduce((a,b)=> { return a + b.intelectuais}, 0)}</b></td>
                            <td><b>{entidades.reduce((a,b)=> { return a + b.staff}, 0)}</b></td>
                            <td><b>{entidades.reduce((a,b)=> { return a + (b.total+b.staff)}, 0)}</b></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </>
    )
}