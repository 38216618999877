/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


let current_element;
let cliente_auto_fill = false;


export function Cadastro() {
    const component_name = "admin/diretorias_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValidaded, setIsFormValidaded] = useState(true);
    const [modalidades, setModalidades] = useState([]);
    const [selectedmodalidades, setSelectedModalidades] = useState([]);
    const [showAddComboModal, setShowAddComboModal] = useState(false);
    const [AddComboTitle, SetAddComboTitle] = useState(false);
    const [AddComboInfo, SetAddComboInfo] = useState({ table: "", id_field: "", name_field: "" });
    const [AddComboList, SetAddComboList] = useState([]);


    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { Id } = useParams();



    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadModalidades();
    }
    //#endregion Init


    //#region Loaders
    const LoadModalidades = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Modalidades/List/0/0/0", "POST", "", user.token, 0))
        .then(async(data) => {
            setPageLoading(false);

            if(data.toString() !== "error") {
                setModalidades(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadFuncoes = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Diretorias/Funcoes", "GET", "", user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                data.unshift({id: 0, name: "Escolha uma diretoria"})
                await _formUserRef.current.Select("funcao_id", data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD01)", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD002)", "error");
        });
    }
    //#endregion Loaders


    //#region Form Handlers
    const new_funcao_id = async(value) => {
        let p = prompt("Digite o nome da Funcao");
        if (p !== "" && p != null && p != undefined) {
            await Promise.resolve(new Request().Run("api/Diretorias/SaveFuncao", "POST", { name: p }, user.token))
                .then(async(data) => {
                   await _formUserRef.current.Load({ id: data, name: p }, "funcao_id", data, "append");
                });
        }
    }


    const sup_funcao_id = async () => {
        _EditAddCombo("Funções de Diretoria", "diretorias_funcoes", "funcao_id", "nome");
    }


    const login = async (value) => {
        if(!cliente_auto_fill) return;
        current_element = "#login";
        Promise.resolve(new Request().Run("api/Diretorias/CheckLogin", "POST", {login: value, diretoria_id: Id}, user.token, 0)).then(async(data) => {
            current_element = "";
            if(data.toString() === "exists") {
                window.swal("Alerta", "Esse login já está associado à um usuário já existente! ", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (DIRCAD003)", "error");
        });
    }


    const OnFormLoading = async() => {
        cliente_auto_fill = false;
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
        
        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Id) {
                let unidade_data = await new Request().Run("api/Diretorias/Load?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.diretoria_id !== null) {
                    await _formUserRef.current.Load(unidade_data);
                    setSelectedModalidades(unidade_data.modalidades);
                }
            }

            setTimeout(()=> {
                LoadFuncoes();
            }, 100);

            setTimeout(()=> {
                cliente_auto_fill = true;
            }, 500);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value, args) => {
        try {
            eval(func)(value, args);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();


       
        if(validade) {
            let _senha = _formUserRef.current.GetValue("senha");
            let _resenha =  _formUserRef.current.GetValue("resenha");

            if(_senha !== "" && _senha !== _resenha ) {
                window.swal("Alerta", "Senha e repetição da senha não conferem", "error");
                return;
            }

            setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Diretorias/Save", {diretoria_id: Id, modalidades: selectedmodalidades}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios (DIRPST001)", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Diretoria ou diretor já registrados", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            navigate("/Diretorias");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios (DIRPST002)", "error");
        }

    }


    const _HandleModalidadeChange = (event, item) => {
        let _selectedmodalidades = [...selectedmodalidades];
        let _index = _selectedmodalidades.findIndex(u => u.modalidade_id === item.modalidade_id);


        if(event.target.checked && _index === -1) {
            _selectedmodalidades.push({modalidade_id: item.modalidade_id});  
        } else if(!event.target.checked && _index > -1) {
            _selectedmodalidades.splice(_index, 1);
        }

        setSelectedModalidades(_selectedmodalidades);
    }
    //#endregion Handlers
     

    
    //#region AddCombo Handlers
    const _handleCloseAddComboModal = async() => {
        setShowAddComboModal(false);
        await _formUserRef.current.Select("diretoria_id", [], "api/Diretorias/Funcoes", "GET", "", user.token);
    }

    const _handleSaveAddComboModal = () => {
        
    }


    const _EditAddCombo = (title, table, id_field, name_field) => {
        SetAddComboTitle(title);
        SetAddComboInfo({ table: table, id_field: id_field, name_field: name_field });
        setShowAddComboModal(true);

       
        Promise.resolve(new Request().Run("api/Diretorias/AddComboList", "POST", { table: table, id_field: id_field, name_field: name_field }, user.token))
            .then((data) => {
                SetAddComboList(data);
            });
    }


    const _AddComboBlur = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id, nome: item.name });
        Promise.resolve(new Request().Run("api/Diretorias/AddComboUpdate", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listItem = _list.find(l => l.id == item.id);
                _listItem = item;
                SetAddComboList(_list);
            });
    }


    const _DeleteAddCombo = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id });
        Promise.resolve(new Request().Run("api/Diretorias/AddComboDelete", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listIndex = _list.findIndex(l => l.id == item.id);
               _list.splice(_listIndex, 1);
                SetAddComboList(_list);
            });
    }


    const _AddComboFieldChange = (item, event) => {
        let _list = [...AddComboList];
        let _listItem = _list.find(l => l.id == item.id);
        _listItem.name = event.target.value;
        SetAddComboList(_list);
    }
    //#endregion AddCombo Handlers



    //#region Funtions
  
    //#endregion Functions

    return(
        <div className="main-content Diretorias">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Diretoria</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('diretoria', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'diretoria'} url={'/assets/forms/diretoria.json'} execRef={Exec} token={user.token}></FormGenerator>
                                                <div style={{clear:'both'}}></div>


                                                <br />
                                         
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#home2" role="tab">
                                                            <span className="d-block d-sm-none"><i className="fa fa-home"></i></span>
                                                            <span className="d-none d-sm-block"> <i className="mdi mdi-filter-plus"></i> &nbsp; Modalidades dirigidas  &nbsp;  </span> 
                                                        </a>
                                                    </li>
                                                </ul>
        
                                      
                                                <div className="tab-content p-3 text-muted">
                                                    <div className="tab-pane active" id="home2" role="tabpanel">
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{width:'40px'}}></th>
                                                                        <th>Nome da Modalidade</th>
                                                                        <th style={{width:'70px'}}>Status</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                {
                                                                        modalidades.length > 0 && modalidades.map((item, i) => (
                                                                            <tr key={'titulo'+i}>
                                                                                <td style={{ padding:'11px 0 0 3px'}}>
                                                                                    <input id={"modalidade_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.modalidade_id} checked={selectedmodalidades.findIndex(t => t.modalidade_id === item.modalidade_id) > -1} onClick={(event) => _HandleModalidadeChange(event, item)} disabled={user.nivel>0}/>
                                                                                </td>
                                                                                <td>
                                                                                    {item.nome}
                                                                                </td>
                                                                                <td>
                                                                                <td><button type="button" className={'btn btn-sm ' +(item.ativo===1?'btn-primary':'btn-danger')}>{item.ativo===1?'Ativo':'Inativo'}</button></td>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    
                                                   
                                                </div>

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            <MDBModal show={showAddComboModal} setShow={setShowAddComboModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>{AddComboTitle}</MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseAddComboModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                    {AddComboList.length > 0 ? (<div>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {AddComboList.map((item, i) => (
                                    <tr key={'addc'+i}>
                                        <td style={{ padding: 0 }}> <input type="text" className="form-control inputfull" value={item.name} onChange={(event) => _AddComboFieldChange(item, event)} onBlur={() => _AddComboBlur(item)} /></td>
                                        <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DeleteAddCombo(item)} style={{margin:'1px',padding:'1px',paddingLeft:'7px',paddingRight:'7px'}}><i className="fa fa-trash"></i></button></td>
                                    </tr>
                                 ))}
                            </tbody>
                        </table>
                        </div>) : (<div>Nenhum dado Encontrado</div>)}
                    </MDBModalBody>

                    <MDBModalFooter>
                            <Button variant="secondary" onClick={_handleCloseAddComboModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}