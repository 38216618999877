import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Layout from './Layout.js';
import Home from './components/Admin/Home';
import CadastroNaoFiliado from './components/CadastroNaoFiliado';
import Entidades from './components/Admin/Entidades';
import Atletas from './components/Admin/Atletas';
import AtletasTransferencia from './components/Admin/AtletasTransferencia';
import Diretorias from './components/Admin/Diretorias';
import Modalidades from './components/Admin/Modalidades';
import Transparencia from './components/Admin/Transparencia';
import Eventos from './components/Admin/Eventos';
import EventosAbertos from './components/Admin/EventosAbertos';
import Fichas from './components/Admin/Fichas';
import { Evento as RelatoriosEvento } from './components/Admin/Relatorios/Evento';
import { Cadastro as EntidadeCadastro } from './components/Admin/Entidades/Cadastro';
import { Cadastro as AtletaCadastro } from './components/Admin/Atletas/Cadastro';
import { Cadastro as TranferenciaCadastro } from './components/Admin/AtletasTransferencia/Cadastro';
import { Cadastro as DiretoriaCadastro } from './components/Admin/Diretorias/Cadastro';
import { Cadastro as EventoCadastro } from './components/Admin/Eventos/Cadastro';
import { Perfil } from './components/Admin/Perfil';
import Inscricao from './components/Admin/Inscricao';
import { Participantes } from './components/Admin/PrintService/Participantes';
import { Planilha } from './components/Admin/PrintService/Planilha';
import { PlanilhaEvento } from './components/Admin/PrintService/PlanilhaEvento';
import { Fichas as RelatoriosFichas } from './components/Admin/Relatorios/Fichas';
import { Fichas as PrintFichas } from './components/Admin/PrintService/Fichas';
import { Provas } from './components/Admin/PrintService/Provas';
import { Content as ShareTransparency} from './components/Share/Transparencia';


export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const loadPrestineRef = useRef(false);


    useEffect(() => {
      let wait = setTimeout(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            GetServer();
        }
      }, 200);

    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
           //return children;
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Share/Transparencia" element={<ShareTransparency />} />

                <Route exact path="/CadastroNaoFiliado" element={<CadastroNaoFiliado />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route exact path="/Entidades/" element={<ProtectedRoute><Entidades /></ProtectedRoute>} />
                <Route exact path="/Entidades/:Tipo" element={<ProtectedRoute><Entidades /></ProtectedRoute>} />
                <Route exact path="/Entidade/:Id" element={<ProtectedRoute><EntidadeCadastro /> </ProtectedRoute>} />
                <Route exact path="/Entidade" element={<ProtectedRoute><EntidadeCadastro /> </ProtectedRoute>} />
                <Route exact path="/Perfil" element={<ProtectedRoute><Perfil /> </ProtectedRoute>} />
                <Route exact path="/Atletas/" element={<ProtectedRoute><Atletas /></ProtectedRoute>} />
                <Route exact path="/Atletas/:Tipo" element={<ProtectedRoute><Atletas /></ProtectedRoute>} />
                <Route exact path="/Atleta" element={<ProtectedRoute><AtletaCadastro /> </ProtectedRoute>} />
                <Route exact path="/Atleta/:Id" element={<ProtectedRoute><AtletaCadastro /> </ProtectedRoute>} />
                <Route exact path="/AtletasTransferencia/" element={<ProtectedRoute><AtletasTransferencia /></ProtectedRoute>} />
                <Route exact path="/AtletaTransferencia/" element={<ProtectedRoute><TranferenciaCadastro /> </ProtectedRoute>} />
                <Route exact path="/AtletaTransferencia/:Id" element={<ProtectedRoute><TranferenciaCadastro /> </ProtectedRoute>} />
                <Route exact path="/Diretorias/" element={<ProtectedRoute><Diretorias /></ProtectedRoute>} />
                <Route exact path="/Diretoria/" element={<ProtectedRoute><DiretoriaCadastro /> </ProtectedRoute>} />
                <Route exact path="/Diretoria/:Id" element={<ProtectedRoute><DiretoriaCadastro /> </ProtectedRoute>} />
                <Route exact path="/Modalidades/" element={<ProtectedRoute><Modalidades /></ProtectedRoute>} />
                <Route exact path="/Transparencia/" element={<ProtectedRoute><Transparencia /></ProtectedRoute>} />
                <Route exact path="/Eventos/" element={<ProtectedRoute><Eventos /></ProtectedRoute>} />
                <Route exact path="/Evento/" element={<ProtectedRoute><EventoCadastro /> </ProtectedRoute>} />
                <Route exact path="/Evento/:Id" element={<ProtectedRoute><EventoCadastro /> </ProtectedRoute>} />
                <Route exact path="/Inscricao/:Id" element={<ProtectedRoute><Inscricao /> </ProtectedRoute>} />
                <Route exact path="/Inscricao/:Id/:Eid" element={<ProtectedRoute><Inscricao /> </ProtectedRoute>} />
                <Route exact path="/EventosAbertos/" element={<ProtectedRoute><EventosAbertos /></ProtectedRoute>} />
                <Route exact path="/Fichas/" element={<ProtectedRoute><Fichas/></ProtectedRoute>} />
                <Route exact path="/Relatorios/Evento/:EventoId" element={<ProtectedRoute><RelatoriosEvento/></ProtectedRoute>} />
                <Route exact path="/PrintService/Participantes/:EntidadeId/:EventoId" element={<ProtectedRoute><Participantes /></ProtectedRoute>} />
                <Route exact path="/PrintService/Planilha/:EventoId" element={<ProtectedRoute><Planilha /></ProtectedRoute>} />
                <Route exact path="/PrintService/PlanilhaEvento/:EventoId" element={<ProtectedRoute><PlanilhaEvento /></ProtectedRoute>} />
                <Route exact path="/Relatorios/Fichas/:EventoId" element={<ProtectedRoute><RelatoriosFichas/></ProtectedRoute>} />
                <Route exact path="/PrintService/Fichas/:EntidadeId/:EtapaId" element={<ProtectedRoute><PrintFichas /></ProtectedRoute>} />
                <Route exact path="/PrintService/Provas/:EtapaId" element={<ProtectedRoute><Provas /></ProtectedRoute>} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}