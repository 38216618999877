/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import { Form, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { UploadService } from '../../../scripts/UploadService';
import { AppServer } from '../../../scripts/Loadbalancer';
import './Lista.css';


const tipos_doc = [
    {id:0, name: "Cartão CNPJ"},
    {id:1, name: "Ata de Eleição"},
    {id:2, name: "Estatuto"}
];


export default function Lista({...props}) {
    const component_name = "admin/entidades_arquivos_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: ""});
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [tipo, setTipo] = useState(0);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const hiddenFileInput = useRef(null);
    const _uploadedImages = useRef([]);

    const navigate = useNavigate();
    const {EntidadeId} = props;

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


     //#region Init
     const Init = () => {
        _HandlerSearchChange();
     }
    //#endregion Init



    //#region Loaders
    const LoadList = () => {
        _HandlerSearchChange();
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        _HandlerSearchChange(event);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);


        Promise.resolve(new Request().Run("api/Entidades/Arquivos/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", {...search, entidade_id: EntidadeId}, user.token))
            .then(async(data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);

                let alerts = [];
                if(listRef.current.findIndex(l => l.tipo === 0) === -1) alerts.push("Cartão CNPJ");
                if(listRef.current.findIndex(l => l.tipo === 1) === -1) alerts.push("Ata de Eleição");
                if(listRef.current.findIndex(l => l.tipo === 2) === -1) alerts.push("Estatuto");
                props.OnAlert(alerts);

                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);


            Promise.resolve(new Request().Run("api/Entidades/Arquivos/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST",  {...search, entidade_id: EntidadeId}, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);

                    let alerts = [];
                    if(listRef.current.findIndex(l => l.tipo === 0) === -1) alerts.push("Cartão CNPJ");
                    if(listRef.current.findIndex(l => l.tipo === 1) === -1) alerts.push("Ata de Eleição");
                    if(listRef.current.findIndex(l => l.tipo === 2) === -1) alerts.push("Estatuto");
                    props.OnAlert(alerts);

                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleOpen = () => {

    }

    const _HandleDelete = (item, i) => {
        window.swal({
            title: "Alerta!",
            text: "Deseja realmente apagar esse arquivo?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Não",
            closeOnConfirm: false,
            closeOnCancel: false
         },
         function(isConfirm){
           if (isConfirm){
            Promise.resolve(new Request().Run("api/Entidades/Delete", "POST", { arquivo_id: item.arquivo_id}, user.token))
                .then((data) => {
                    let _list = [...list];
                    _list.splice(i, 1);
                    listRef.current = _list;

                    setList(_list);
                })
                window.swal("Ação realizada", "Arquivo apagado com sucesso!", "success");
            } else {
                window.swal("Ação cancelada", "O arquivo não foi apagado", "error");
            }
         });
    }


    const _HandleDownload = () => {
        
    }


    const _HandleTipoChange = (event) => {
        setTipo(event.target.value);
    }
    //#endregion Handlers



    //#region Upload
    const _handlerUploadClick = () => {
        let index = list.findIndex(l => parseInt(l.tipo) === parseInt(tipo));
        if(index > -1) {
            window.swal("Ação cancelada", "Já existe um documento " + tipos_doc[tipo].name + ".\n Primeiro apague o documento existente", "error");            
        } else {
            hiddenFileInput.current.click();
        }
    }


    const _HandleFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
           
        };
        reader.readAsDataURL(files[0]);
        Upload(e);
    }


    const Upload = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.Data = {entidade_id: EntidadeId, tipo: tipo};
        upload.OnEnd = (result) => {
            c++;

            if(c === event.target.files.length) {
                LoadList(); 
                setPageLoading(false);
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

       
        await upload.PopUpload(AppServer("/api/Entidades") + "api/Entidades/Upload", user.token);
    }
    //#endregion Upload


    


    return (
        <div className="_card Entidade">
            <div className="card-header justify-content-between _d-flex _align-items-center">
                <h4 className="card-title"> </h4>

                <div className="row"> 
                    <div className="col-sm-12"> 
                        <div className="align-right">
                            <div className="search-box align-left mr-10">
                                <select id="tipo_doc" className="form-select" value={tipo} onChange={_HandleTipoChange} aria-label={"Tipo de Arquivo"}>
                                    <option value="0">Cartão CNPJ</option>
                                    <option value="1">Ata de Eleição</option>
                                    <option value="2">Estatuto</option>
                                </select> 
                            </div> 

                            <div className="align-left mr-10">
                                <input type="file" ref={hiddenFileInput} onChange={_HandleFileChange} style={{display: 'none'}} accept=".*"/>
                                <button type="button" className="btn btn-primary" onClick={_handlerUploadClick}>
                                    <i className="mdi mdi-star-plus"></i> &nbsp;Enviar Arquivo
                                </button>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body pb-0">
                <div className="table-responsive">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={_fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Carregando...</b>
                            </p>
                        }
                        style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                        endMessage={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Não há mais dados</b>
                            </p>
                        }>    

                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Nome Original</th>
                                    <th>Arquivo</th>
                                    <th style={{width:'50px'}} className={(user.nivel>1?'hide':'')}></th>
                                </tr>
                            </thead>


                            <tbody>
                            {list.map((item, i) => (
                                <tr key={'tr' + i} className={'pointer '}>
                                    <td>{tipos_doc[item.tipo].name}</td>
                                    <td>{item.nome}</td>
                                    <td><a href={'https://painel.fpdc.org.br/storage/entidades/'+item.arquivo} target="_blank" rel="noreferrer">{item.arquivo}</a></td>
                                    <td><i className={'fa fa-trash '} onClick={(e) => _HandleDelete(item, i)}></i></td>
                                </tr>
                            ))}
                            
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}