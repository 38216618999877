import React from 'react';
import RouteManager from './RouteManager';
import { BrowserRouter as Router} from 'react-router-dom';

import { AuthProvider } from './Context/AuthContext';
import { HubProvider } from './Context/HubContext';
import { InvalidatorProvider } from './Context/InvalidatorContext';


const App = () => (
  <Router>
    <HubProvider>
      <AuthProvider>
          <InvalidatorProvider>
            <RouteManager />
          </InvalidatorProvider>
      </AuthProvider>
    </HubProvider>
  </Router>
);

export default App;
