export const HubObjects = {
    _objects: [],
    _observers: [],

    //#region Object
    Set: (key, value, caller) => {
       HubObjects._objects[key] = value;
        if(caller) HubObjects._objects[key+'_caller'] = caller;
    },


    Get: (key) => {
        try {
            return HubObjects._objects[key];
        } catch (e) {
            console.log("Get Error: " + e.message);
        }
    },


    Exec: (key, ...parms) => {
        try {
            return HubObjects._objects[key](...parms);
        } catch (e) {
            console.log("Exec Error: " + key + " / " + e.message);
        }
    },


    ExecAsync: (key, ...parms) => {
        let c = 0;
        let wait_exist = setInterval(() => {
            if(HubObjects.Exists(key)) {
                clearInterval(wait_exist);
                try {
                    return HubObjects._objects[key](...parms);
                } catch (e) {
                    console.log("Exec Error: " + key + " / " + e.message);
                }
            } else {
                c++;
                if(c > 3000) {
                    console.log("Exec Timeout: " + key);
                }
            }
        }, 100);

    },

    Push: (key, value) => {
        HubObjects._objects[key].push(value);
    },

    Clear: (caller) => {
        try {
            if(caller) { //For Disposable Object
                let fcallers = Object.entries(HubObjects._objects);
                let fcallers_list = fcallers.filter(item =>  { return item[1] === caller });

                for(let i=fcallers.length-1; i>=0; i--) {
                    let fcaller = fcallers[i];
                    fcallers_list.map ((item, i) => {
                        if(item[0].replace("_caller", "") === fcaller[0].replace("_caller", "")) {
                            fcallers.splice(i, 1);
                        }
                    });
                }

                HubObjects._objects = [];
                fcallers.map((item, i) => {
                    HubObjects._objects[item[0]] = item[1];
                });
            } else {
                return HubObjects._objects = [];
            }
        } catch (e) {
            
        }
    },


    Exists: (key) => {
        return HubObjects._objects[key] !== undefined ? true : false;
    },


    List: () => {
        Object.entries(HubObjects._objects).forEach(([key, value]) => {
            console.log(key, value);
        });
    },
    //#endregion Object


    //#region Observers
    Observer: (key, caller) => {
        //console.log("Observer", key);
        HubObjects._observers[key] = [];
        if(caller) HubObjects._observers[key+'_caller'] = caller;
    },


    Subscribe: (key, obj) => {
        //console.log("Subscribe");
        HubObjects.Unsubscribe(key, obj);
        HubObjects._observers[key].push(obj);

        //console.log(HubObjects._observers[key]);
        //console.log(HubObjects._observers[key].length);
    },


    Unsubscribe: (key, obj) => {
        if(obj) {
            HubObjects._observers[key] = HubObjects._observers[key].filter(subscriber => subscriber !== obj);
        } else {
            HubObjects._observers[key] = [];
        }
    },

    UnsubscribeAll: (key) => HubObjects.Observer(key),

    ObserverExists: (key) => {
        return HubObjects._observers[key] !== undefined ? true : false;
    },


    Notify: (key, data) => {
        //console.log("Notify ", HubObjects._observers[key].length);
        HubObjects._observers[key].forEach(observer => observer(data));
    }
    //#endregion Observes
}