export const Wait = async (element, timeout=10000) => {
    let _self = document;

    return new Promise(resolve => { 
        let _c = 0;
        let _wait = setInterval(()=> {
            if(_self.querySelector(element)) {
                clearInterval(_wait);
                _c = 0;
                resolve(_self.querySelector(element));
            } else {
                if(_c >=  (timeout/100)) {
                    clearInterval(_wait);
                    console.error("Element timeout", element);
                    _c = 0;
                }
                _c++;
            }
        }, 100);
    });
}


export const WaitObject = async (element, timeout=10000, log = false) => {
    return new Promise((resolve, reject) => { 
        let _c = 0;
        let _wait = setInterval(()=> {
            let r = false;
            if(typeof element === 'function') {
                try {
                    r = element();
                } catch(e) { 
                    r = false;
                }

                if(r){
                    clearInterval(_wait);
                    _c = 0;
                    if(log) console.log("wait resolved");
                    resolve(element);
                } else {
                    if(_c >=  (timeout/100)) {
                        clearInterval(_wait);
                        //reject();
                        console.error("Element timeout", element);
                        _c = 0;
                    }
                    if(log) console.log(_c);
                    _c++;
                }
            } else {
                if(element){
                    clearInterval(_wait);
                    _c = 0;
                    if(log) console.log("wait resolved");
                    resolve(element);
                } else {
                    if(_c >=  (timeout/100)) {
                        clearInterval(_wait);
                        //reject();
                        console.error("Element timeout", element);
                        _c = 0;
                    }
                    if(log) console.log(_c);
                    _c++;
                }
            }
        }, 100);
    })
}





export const Sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}