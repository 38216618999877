/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Req, Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ServerURL } from '../../../scripts/Loadbalancer';
import { Currency, UnCurrency, Uuid } from '../../../scripts/StringUtils';
import { UploadService } from '../../../scripts/UploadService';
import ReactGA from 'react-ga';
import './Cadastro.css';



let current_element;
let cliente_auto_fill = false;
let modalidades_data = [];
let provasData = [];


export default function Etapa({...props}) {
    const component_name = "admin/etapa_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [provas, setProvas] = useState([]);
    const [provaTipo, setProvaTipo] = useState("");
    const [selectedprovas, setSelectedProvas] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const { EventoId, Id, EventoName } = props;

    


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        
    }
    //#endregion Init


    //#region Loaders
    const LoadProvas = async() => {
        let provas_nat = await Req("/assets/json/provas.json", "GET", "", "", 0*24*60*1000);
        if(provas_nat.toString() !== "error") {
            let _old = "";
            provas_nat.natacao.map(item => {
                if(item.estilo !== _old) {
                    _old = item.estilo;
                } else {
                    item.estilo = "";
                }
            })
            provasData = provas_nat;
            setProvas(provas_nat);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
        }

    
        return true;
    }


    const LoadModalidades = async (_modalidade_id = "") => {
        setPageLoading(true);

        let data = await Req("api/Eventos/Modalidades", "GET", "", user.token, 0);
        setPageLoading(false);

        if(data.toString() !== "error") {
            modalidades_data = data;
            setModalidades(data);

            await _formUserRef.current.Select("modalidade_id", data);
            if(_modalidade_id !== "") {
                await _formUserRef.current.Value("modalidade_id", _modalidade_id);
                setTimeout(() => {
                    console.log("LoadModalidades", _modalidade_id);
                    modalidade_id(_modalidade_id);
                }, 300);
            }

            console.log("Modalidades Loaded");
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD003)", "error");
        }
    }
    //#endregion Loaders



    //#region Form Handlers
    const modalidade_id = (value) => {
        if(!value) return;

        let _modalidades = modalidades_data;
        let _modalidade_name = _modalidades.find(m => m.id === parseInt(value)).name;
        
        if(_modalidade_name && _modalidade_name !== undefined) {
            console.log(_modalidade_name, _modalidades)
            if(_modalidade_name.toLowerCase().indexOf("atletismo") > -1) {
                setProvaTipo("atletismo");
            } else if(_modalidade_name.toLowerCase().indexOf("natação") > -1) {
                setProvaTipo("natacao");
            } else {
                setProvaTipo("");
            }
        } else {
            setProvaTipo("");
        }
    }


    const OnFormLoading = async() => {
    
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
        

        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(Id) {
                let unidade_data = await new Request().Run("api/Eventos/LoadEtapa?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.etapa_id !== null) {
                    await _formUserRef.current.Load(unidade_data);
                    setTimeout(async() => {
                        await LoadProvas();
                        await LoadModalidades(unidade_data.modalidade_id);
                        setSelectedProvas(unidade_data.provas);
                    }, 100);
                } else {
                    await _formUserRef.current.Value('modalidade_id', 1);
                    setTimeout(async() => {
                        await LoadProvas();
                        await LoadModalidades(1);
                    }, 100);
                }
            } else {
                await _formUserRef.current.Value('modalidade_id', 1);
                setTimeout(async() => {
                    await LoadProvas();
                    await LoadModalidades(1);
                }, 100);
            }
            

         

            setPageLoading(false);
        });
        
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
            let _modalidades = [...modalidades];
            let _modalidade_name = await _formUserRef.current.GetValue("modalidade_id")?.name

            console.log(_modalidade_name);

            let _provas = [];
            if(_modalidade_name && _modalidade_name !== undefined) {
                if(_modalidade_name.toLowerCase().indexOf("atletismo") > -1) {
                    _provas = [...selectedprovas];

                    if(_provas.length === 0) {
                        window.swal("Alerta", "Nenhuma prova foi escolhida", "error");
                        return;
                    }
                } else if(_modalidade_name.toLowerCase().indexOf("natação") > -1) {
                    _provas = [...selectedprovas];

                    if(_provas.length === 0) {
                        window.swal("Alerta", "Nenhuma prova foi escolhida", "error");
                        return;
                    }
                }
            }
            

            setPageLoading(true);
            
            let result = await _formUserRef.current.Post("api/Eventos/EtapaSave", {provas: _provas, evento_id: EventoId, etapa_id: Id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Evento já registrado", "error");
                return;
            }


            props.onSave();
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }


    const _HandleBack = () => {
        props.onClose()
    }


    const _HandleProvaChange = (event, item) => {
        let _selectedprovas = [...selectedprovas];
        let _index = _selectedprovas.findIndex(u => u.prova_id === item.prova_id);

        //console.log(item);

        if(event.target.checked && _index === -1) {
            _selectedprovas.push({prova_id: item.prova_id, niveis: item.niveis});  
        } else if(!event.target.checked && _index > -1) {
            _selectedprovas.splice(_index, 1);
        }

        //console.log(_selectedprovas);
        setSelectedProvas(_selectedprovas);
    }


    const _HandleNiveisChange = (event, item) => {
        let _provas = {...provas};
        let _prova = _provas[provaTipo].find(p => p.prova_id === item.prova_id);
        _prova.niveis = event.target.value;
        setProvas(_provas);

        let _selectedprovas = [...selectedprovas];
        let _selectedprova =  _selectedprovas.find(u => u.prova_id === item.prova_id);
        _selectedprova.niveis = event.target.value;
        setSelectedProvas(_selectedprovas);

    }
    //#endregion Handlers


    return (
            <div className="row Etapa">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header justify-content-between d-flex align-items-center">
                            <h4 className="card-title">Cadastro de Etapa / Ficha</h4>
                            <button type="button" className="btn btn-outline-primary right"  onClick={_HandleBack} ><i class="mdi mdi-arrow-left"></i> &nbsp;Voltar</button>
                        </div>

                        <div className="card-body pb-2">
                            
                            <form name="fetapa" onSubmit={(event) => _HandleSubmit('etapa', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                <div className="row">
                                    <div className="col-md-12 pt-1 pb-1">
                                        <FormGenerator ref={_formUserRef} name={'etapa'} url={'/assets/forms/etapa.json'} execRef={Exec}></FormGenerator>
                                        <div style={{clear:'both'}}></div>
                                        
                                        <br />
                                    </div> 
                                </div>

                                <div className={'table-responsive ' + (provaTipo !== ''?'':'hide')}>
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{width:'40px'}}></th>
                                                <th>Estilo/Prova</th>
                                                <th>Niveis de deficiência</th>
                                                <th>Evento</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {
                                               provaTipo !== '' && Array.isArray(provas[provaTipo]) && provas[provaTipo].length > 0 && provas[provaTipo].map((item, i) => (
                                                    <>
                                                    {item.estilo && item.estilo !== '' && (<tr className="tr_head"><td colSpan={3}><b>{item.estilo}</b></td></tr>)}
                                                    
                                                    <tr key={'titulo'+i}>
                                                        <td style={{ padding:'11px 0 0 3px'}}>
                                                            <input id={"titulo_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.prova_id} checked={selectedprovas.findIndex(t => t.prova_id === item.prova_id) > -1} onClick={(event) => _HandleProvaChange(event, item)} disabled={user.nivel>0}/>
                                                        </td>
                                                        <td>
                                                            {item.nome}
                                                        </td>
                                                        <td style={{padding:'3px'}}>
                                                            <input type="text" id={"niveis_i"+i} className="form-control" value={selectedprovas.find(p => p.prova_id===item.prova_id)?.niveis} onChange={(event) => _HandleNiveisChange(event, item)} disabled={selectedprovas.findIndex(t => t.prova_id === item.prova_id) === -1}/>
                                                        </td>
                                                        <td>
                                                            {EventoName}
                                                        </td>
                                                    </tr>
                                                    </>
                                                ))
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                <center>
                                    <hr />
                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit"> &nbsp; Salvar Etapa &nbsp; </button>
                                </center>
                            </form>

                            
                        </div>

                        
                    </div>
                </div>


                
            </div>
          
    )
}