/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { cpf as CpfValidate} from 'cpf-cnpj-validator'; 
import ReactGA from 'react-ga';

import Lista from './Lista';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';



let current_element;
let cliente_auto_fill = false;


export function Cadastro() {
    const component_name = "admin/atletas_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValidaded, setIsFormValidaded] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [atletaId, setAtletaId] = useState(undefined);
    const [ativo, setAtivo] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [justificativa, setJustificativa] = useState("");

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const _entidadeTipoRef = useRef(0);

    const navigate = useNavigate();

    const { Id } = useParams();



    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        
    }
    //#endregion Init


    //#region Loaders
    const LoadEntidades = (word = "") => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Atletas/Entidades", "POST", {word: word, entidade_id: (user.nivel===3?user.entidade_id:''), tipo: _entidadeTipoRef.current}, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                data.unshift({id: 0, name: "Nenhuma entidade associada"})
                await _formUserRef.current.Select("entidade_id", data);
                if(user.nivel===3) {
                     await _formUserRef.current.Value("entidade_id", user.entidade_id);
                }
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATCAD001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATCAD002)", "error");
        });
    }


   
    //#endregion Loaders


    //#region Form Handlers
    const cpf = async (value) => {
        if(!cliente_auto_fill) return;
        setIsFormValidaded(false);
    }


    const blur_cpf = async (value) => {
        if(!CpfValidate.isValid(value)) {
            window.swal("Alerta", "CPF inválido", "error");
            return;
        }

        current_element = "#cpf";
        Promise.resolve(new Request().Run("api/Atletas/CheckCPF", "POST", {cpf: value, atleta_id: Id}, user.token, 0)).then(async(data) => {
            current_element = "";
            if(data.toString() === "exists") {
                window.swal("Alerta", "Esse CPF já está associado à uma entidade existente! ", "error");
            } else {
                setIsFormValidaded(true);
            }

        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ENTCAD002)", "error");
        });
    }
        


    
    const OnFormLoading = async() => {
        cliente_auto_fill = false;
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);

        _entidadeTipoRef.current = await Hub.Get("ENTIDADE_FILIADA");
        
        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            let unidade_data;
            if(Id) {
                setAtletaId(Id);
                unidade_data = await new Request().Run("api/Atletas/Load?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.entidade_id !== null) {
                    if(user.nivel === 3)  await _formUserRef.current.DisableFields(["entidade_id"], false);
                    await _formUserRef.current.Load(unidade_data);
                    setAtivo(unidade_data.ativo);
                    ValidadeCPF(unidade_data.cpf);

                    setTimeout(()=> {
                        LoadEntidades();
                    }, 100);
                }
            } else {
                setTimeout(()=> {
                    LoadEntidades();
                }, 100);
            }

           

            setTimeout(()=> {
                cliente_auto_fill = true;
            }, 500);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
             setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Atletas/Save", {atleta_id: Id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "O atleta já esta salvo!", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            /*if(_entidadeTipoRef.current !== "") {
                navigate("/Atletas/" + _entidadeTipoRef.current );
            } else {
                navigate("/Atletas");
            }*/

            setAtletaId(result);
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }



    const _HandleBack = async() => {
        /*if(_entidadeTipoRef.current !== "" && _entidadeTipoRef.current) {
            navigate("/Atletas/" + _entidadeTipoRef.current );
        } else {
            navigate("/Atletas");
        }*/

        let tipo = await Hub.Get("ATLETA_TIPO_FILTER");
        if(tipo) {
            navigate("/Atletas/"+tipo);
        } else {
            navigate("/Atletas");
        }
    }


    const _HandlerAlert = (alerts) => {
        setAlerts(alerts);
    }


    const _HandleAtivarAtleta = (valid) => {
        Promise.resolve(new Request().Run("api/Atletas/Ativo", "POST", { atleta_id: Id, ativo: valid}, user.token))
        .then((data) => {
           setAtivo(data);
        });
    }



    const _handleCloseModal = () => {
        setShowModal(false);
    }


    const _handleOpenModal = () => {
        setShowModal(true);
    }


    const _handleSendModal = async(event) => {
        let _entidade_id = await _formUserRef.current.GetValue("entidade_id")?.id;
        await Req("api/Atletas/Justificativa", "POST", { atleta_id: Id, entidade_id: _entidade_id, justificativa: justificativa }, user.token).then(data => {
            setShowModal(false);
            setJustificativa("");
            window.swal("Sucesso", "Justificativa enviada com sucesso!", "success");
        }).catch(err => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde!", "error");
        })
    }


    const _HandleJustificativa = (event) => {
        setJustificativa(event.target.value);
    }
    //#endregion Handlers
     

    


    
    //#region Funtions
    const ValidadeCPF = (value) => {
        if(Id) {
            if(!CpfValidate.isValid(value)) {
                setIsFormValidaded(false);
                window.swal("Alerta", "CPF inválido", "error");
            } else {
                setIsFormValidaded(true);
            }
        }
    }
    //#endregion Functions





    return(
        <div className="main-content Atletas">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Atleta {Array.isArray(alerts) && alerts.length > 0 && (<span style={{color:'red'}}> &nbsp; (Documentos faltando: {alerts.join(', ')})</span>)}</h4>
                                    <div className="align-right mr-10">
                                        <button type="button" className="btn btn-outline-primary mr-10" onClick={_HandleBack}>
                                            <i className="mdi mdi-arrow-left"></i> &nbsp;Lista &nbsp;
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('atleta', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'atleta'} url={'/assets/forms/atleta.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit" disabled={!isFormValidaded}>Salvar informações</button> &nbsp; 
                                                    {ativo === 0  && user?.nivel === 0 && (
                                                        <>
                                                        <button id="btn_ativo" className="btn btn-info btn-lg btn-rounded mb-2" type="button" onClick={() => _HandleAtivarAtleta(0)}>Ativar Atleta</button> &nbsp; 
                                                        <button id="btn_ativo" className="btn btn btn-outline-purple btn-lg btn-rounded mb-2" type="button" onClick={() => _handleOpenModal(0)}>Indeferimento</button>
                                                        </>
                                                    )}

                                                    {ativo === 1  && user?.nivel === 0 && (
                                                    <button id="btn_ativo" className="btn btn-danger btn-lg btn-rounded mb-2" type="button" onClick={() => _HandleAtivarAtleta(1)}>Inativar Atleta</button>
                                                    )}
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>

                                    { atletaId && (
                                        <Lista AtletaId={atletaId} OnAlert={_HandlerAlert}/>
                                    )}
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>

            <MDBModal staticBackdrop show={showModal} setShow={setShowModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><h4>Justificativa de deferimento</h4></MDBModalTitle>
                            {/*<Button className='btn btn-primary' onClick={_handleClosePhotoModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>*/}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <textarea id="justificativa" value={justificativa} style={{width: '100%', height:'250px'}} onChange={_HandleJustificativa}>{justificativa}</textarea>
                        </MDBModalBody>

                        <MDBModalFooter>
                        <Button variant="primary" onClick={_handleSendModal}>
                            Enviar Justificativa
                        </Button>
                        <Button variant="secondary" onClick={_handleCloseModal}>
                            Fechar
                        </Button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}