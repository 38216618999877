/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import Lista from './Lista';
import Etapa from './Etapa';


let current_element;
let cliente_auto_fill = false;
let BUSY_COMPONENT = false;

export function Cadastro() {
    const component_name = "admin/evento_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isFormValidaded, setIsFormValidaded] = useState(false);

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    const { Id } = useParams();
    const [itemId, setItemId] = useState(Id);
    const [editId, setEditId] = useState("");
    const [eventoName, setEventoName] = useState("");
    const [editable, setEditable] = useState(false);
    

    const editIdRef = useRef("");

    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
      
    }
    //#endregion Init


    //#region Loaders
    const LoadUfs = (uf = 26) => {
        Promise.resolve(new Request().Run("/assets/json/estados.json", "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("uf_codigo", data);
            await _formUserRef.current.Value("uf_codigo", uf);
            LoadCidades(uf)

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (CADEVT001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (CADEVT002)", "error");
        });
    }


    const LoadCidades = (uf_codigo, city = "") => {
        Promise.resolve(new Request().Run("api/Eventos/Cidades?uf_codigo=" + uf_codigo, "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("cidade_codigo", data);

            if(city !== "") {
                city = city.toLocaleUpperCase();
                let _id = data.find(c => c.name === city).id;
                await _formUserRef.current.Value("cidade_codigo", _id);
            }

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (CADCID001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (CADCID002)", "error");
        });
    }


    //#endregion Loaders


    //#region Form Handlers
    const uf_codigo = value => {
        LoadCidades(value);
    }


    const nome = value => {
        setEventoName(value);
    }
    
    const OnFormLoading = async() => {
        cliente_auto_fill = false;
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
        WaitObject(() => _formUserRef.current.IsLoaded()).then(async(data) => {
            if(Id) {
                let unidade_data = await new Request().Run("api/Eventos/Load?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.evento_id !== null) {   
                    await _formUserRef.current.Load(unidade_data);
                    setEventoName(unidade_data.nome);
                }
            }

            setTimeout(()=> {
                LoadUfs();
            }, 100);

            setTimeout(()=> {
                cliente_auto_fill = true;
            }, 500);

            setPageLoading(false);
        }); 
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleAddEtapa = (id) => {
        editIdRef.current = id;
        setEditId(id?id:undefined);
        setEditable(true);
        _formUserRef.current.DisableFields();
    }


    const _HandleSaveEtapa = () => {
        setEditId(undefined);
        setEditable(false);
        _formUserRef.current.DisableFields(undefined, true);
    }

    const _HandleCloseEtapa = () => {
        setEditId(undefined);
        setEditable(false);
        _formUserRef.current.DisableFields(undefined, true);
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {

             setPageLoading(true);
            //_formUserRef.current.Test("","");
            let result = await _formUserRef.current.Post("api/Eventos/Save", {evento_id: Id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Evento já registrado", "error");
                return;
            } else {
                setItemId(result);
            }


            if(Id) {
                window.scrollTo({top: 0, behavior: 'smooth'});
                navigate("/Eventos");
            }
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }


    const _HandleBack = () => {
        navigate("/Eventos");
    }
    //#endregion Handlers
     

    //#region Funtions
  
    //#endregion Functions

    return(
        <div className="main-content Eventos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Eventos</h4>
                                    <button type="button" className="btn btn-outline-primary right"  onClick={_HandleBack} ><i class="mdi mdi-arrow-left"></i> &nbsp;Voltar</button>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('evento', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'evento'} url={'/assets/forms/evento.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                               
                                                <br />
                                            </div> 
                                        </div>

                                        <center>
                                            <hr />
                                            <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                                        </center>
                                    </form>

                                    { itemId && (
                                        <div>
                                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-bs-toggle="tab" href="#etapas" role="tab">
                                                        <span className="d-block d-sm-none"><i className="fa fa-home"></i></span>
                                                        <span className="d-none d-sm-block"> <i className="mdi mdi-filter-plus"></i> &nbsp; Etapas / Fichas do Evento &nbsp;  </span> 
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content text-muted">
                                                <div className="tab-pane active" id="etapas" role="tabpanel">
                                                    { !editable && (<Lista EventoId={itemId} onEdit={_HandleAddEtapa} />) }
                                                    { editable && (<Etapa EventoId={itemId} Id={editIdRef.current} EventoName={eventoName} onSave={_HandleSaveEtapa} onClose={_HandleCloseEtapa}/>) }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>

        </div>
    )
}