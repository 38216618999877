/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import {Currency, UnCurrency } from '../../../scripts/StringUtils'
import { WaitObject } from '../../../scripts/Wait';
import { Button, Modal } from "react-bootstrap";
import './Time.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';



/*
Judo -------------------------------------------------------------------------------
Categoria/Peso 
<option>Até 60 kg</option>
<option>60 a 66 kg</option>
<option>66 a 73 kg</option>
<option>73 a 81 kg</option>
<option>81 a 90 kg</option>
<option>90 a 100 kg</option>
<option>+ que 100 kg</option>

<option>Até 48 kg</option>
<option>48 a 52 kg</option>
<option>52 a 57 kg</option>
<option>57 a 63 kg</option>
<option>63 a 70 kg</option>
<option>70 a 78 kg</option>
<option>+ que 78 kg</option>

Faixa 
<option>Branca</option>
<option>Azul</option>
<option>Amarela</option>
<option>Laranja</option>
<option>Verde</option>
<option>Roxa</option>
<option>Marrom</option>
<option>Preta</option>


Futebol5 -------------------------------------------------------------------------------
Função
<option>Jogador</option>
<option>Goleiro</option>
*/



let datalist_timer;
let datalist_result;
let selected_atleta;
let provasData = [];
let valorEvento = 0;

let staff_list = [
    {id: 4, name: "Atleta"},
    {id: 1, name: "Técnico *"},
    {id: 2, name: "Auxiliar Técnico"},
    {id: 3, name: "Staff1 (acompanhante)"}
]

export default function Time(props) {
    const component_name = "admin/inscricao_etapa";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [listStaff, setStaffList] = useState([
        {atleta_id: "", staff: 1, nome: "", cl_visual: 1, categoria: 1, pay: 1},
        {atleta_id: "", staff: 2, nome: "", cl_visual: 1, categoria: 1, pay: 1},
        {atleta_id: "", staff: 3, nome: "", cl_visual: 1, categoria: 1, pay: 1}
    ]);
    const [atletas, setAtletas] = useState([]);
    const [provas, setProvas] = useState([]);
    const [atleta, setAtleta] = useState({});
    const [ficha, setFicha] = useState({});
    const [search, setSearch] = useState({ word: "", staff: 4 });
    const [showModal, setShowModal] = useState(false);
    const [selAtleta, setSelAtleta] = useState({});
    const [anyChange, setAnyChange] = useState(false);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const { EntidadeId, EventoId, EtapaId, TimeId, EditableMode, Modalidade, Provas } = props;



    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    //Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        setTimeout(async() => {
            await LoadProvas();
            setTimeout(async() => { await LoadFicha()}, 100);
        }, 100);
    }, [EntidadeId, Modalidade])


    useEffect(() => {
        let isOk = true;
        if(list.length === 0 
            ||  (listStaff[0].nome === "" &&  Modalidade.toLowerCase().indexOf("xadrez") === -1) 
            || (list.findIndex(l => !l?.provas || l.provas.length === 0) > -1 && (Modalidade?.toLowerCase().indexOf("atletismo") > -1 || Modalidade?.toLowerCase().indexOf("natação") > -1 )) ) isOk = false;

        //props.OnAtletasChange(list.length, TimeId, isOk, listStaff.filter(s => {return s.atleta_id !== "" && s.nome !== ""}).length, ficha?.ficha_id);
        //console.log(list, listStaff);
        props.OnAtletasChange(list.length, TimeId, isOk, listStaff, ficha?.ficha_id, valorEvento);
    }, [list, listStaff])


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
    const Init = async () => {
        
    }
    //#endregion Init


    //#region Loaders
    const LoadFicha = async() => {
        let result = await Req("api/Inscricao/LoadFicha", "POST", {entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}, user.token, 0);
        
        if(result) {
            setFicha(result);
            props.OnFichaLoad(result?.status, TimeId, result?.ficha_id);

            valorEvento = result.valor_evento;

            let _staff = [...listStaff];
            if(result?.tecnico_id && result?.tecnico_id !== 0) {
                let _index = _staff?.findIndex(s => parseInt(s.staff) === 1);
                if(_index > -1) {
                    _staff[_index].atleta_id = result?.tecnico_id;
                    _staff[_index].nome = result?.tecnico;
                    _staff[_index].pay = result?.tecnico_pay;
                }
            } else {
                _staff[0] = {atleta_id: "", staff: 1, nome: "", cl_visual: 1, categoria: 1, pay: 1}
            }


            if(result?.auxiliar_id && result?.auxiliar_id !== 0) {
                let _index = _staff?.findIndex(s => parseInt(s.staff) === 2);
                if(_index > -1) {
                    _staff[_index].atleta_id = result?.auxiliar_id;
                    _staff[_index].nome = result?.auxiliar;
                    _staff[_index].pay = result?.auxiliar_pay;
                }
            } else {
                _staff[1] = {atleta_id: "", staff: 2, nome: "", cl_visual: 1, categoria: 1, pay: 1}
            }


            if(result?.acompanhante_id && result?.acompanhante_id !== 0) {
                let _index = _staff?.findIndex(s => parseInt(s.staff) === 3);
                if(_index > -1) {
                    _staff[_index].atleta_id = result?.acompanhante_id;
                    _staff[_index].nome = result?.acompanhante;
                    _staff[_index].pay = result?.acompanhante_pay;
                }
            } else {
                _staff[2] = {atleta_id: "", staff: 3, nome: "", cl_visual: 1, categoria: 1, pay: 1}
            }

            setStaffList(_staff);

            if(result?.atletas?.length > 0) {
                setList(result?.atletas);
            } else {
                setList([]);
            }
        } else {
            setList([]);
            setStaffList([
                {atleta_id: "", staff: 1, nome: "", cl_visual: 1, categoria: 1, pay: 1},
                {atleta_id: "", staff: 2, nome: "", cl_visual: 1, categoria: 1, pay: 1},
                {atleta_id: "", staff: 3, nome: "", cl_visual: 1, categoria: 1, pay: 1}
            ]);
        }

        return true;
    }


    const LoadProvas = async() => {
        //WaitObject(Modalidade).then(async() => {
            let _provas = await Req("/assets/json/provas.json", "GET", "", "", 0*24*60*1000);
            if(_provas.toString() !== "error") {
                /*let _old = "";
                _provas.natacao.map(item => {
                    if(item.estilo !== _old) {
                        _old = item.estilo;
                    } else {
                        item.estilo = "";
                    }
                })*/
                provasData = _provas[(Modalidade?.toLowerCase().indexOf("atletismo") > -1?'atletismo':'natacao')];
                setProvas(_provas[(Modalidade?.toLowerCase().indexOf("atletismo") > -1?'atletismo':'natacao')]);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATECAD001)", "error");
            }

            return true;
        //})
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleDatalistAll = async() => {
        datalist_result = await Req("api/Inscricao/Atletas", "POST", {word: "", entidade_id: EntidadeId, etapa_id: EtapaId, staff: search.staff}, user.token, 0);
        setAtletas(datalist_result);
        setAtleta(undefined);
        setSearch(prev => prev = {...prev, word: ""});
        window.$("#word").val("");
        if(datalist_result?.length > 0) {
            window.$("#word").attr("placeholder", "Clique aqui para abri a lista");
        } else {
            window.$("#word").attr("placeholder", "");
            window.swal("Alerta", "Não há atletas elegíveis na sua entidade", "error");
        }
    }


    const _HandleDatalistChange = (event) => {
        setSearch(prev => prev = {...prev, word: event.target.value});
        window.$("#word").attr("placeholder", "");
        clearTimeout(datalist_timer);

        if(event.target.value.length >= 1) {
            datalist_timer = setTimeout(async() => {

                if(datalist_result && datalist_result.toString() !== "error" && datalist_result.length > 0) {
                    let p0 = event.target.value.indexOf(". ") ;
                    if(p0 > -1) {
                        let _id = event.target.value.substr(0, p0);
                        let _name = event.target.value.substr(p0+2);

                        let finded = datalist_result.find(f => f.name === _name && f.id === parseInt(_id));
                        if(finded) {
                            //console.log(finded);
                            selected_atleta = finded;
                            setAtleta(finded);
                        } else {
                            datalist_result = await Req("api/Inscricao/Atletas", "POST", {word: event.target.value, entidade_id: EntidadeId, etapa_id: EtapaId, staff: search.staff}, user.token, 0);
                            setAtletas(datalist_result);
                            setAtleta(undefined);
                            selected_atleta = undefined;
                        }
                    } else {
                        datalist_result = await Req("api/Inscricao/Atletas", "POST", {word: event.target.value, entidade_id: EntidadeId, etapa_id: EtapaId, staff: search.staff}, user.token, 0);
                        setAtletas(datalist_result);
                        setAtleta(undefined);
                        selected_atleta = undefined;
                    }

                } else {
                    datalist_result = await Req("api/Inscricao/Atletas", "POST", {word: event.target.value, entidade_id: EntidadeId, etapa_id: EtapaId, staff: search.staff}, user.token, 0);
                    setAtletas(datalist_result);
                    setAtleta(undefined);
                    selected_atleta = undefined;
                }  
            }, 700);
        }
    }


    const _HandleSearchChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value, word: ""});
        window.$("#word").attr("placeholder", "");
        setAtletas([]);
        setAtleta(undefined);
        selected_atleta = undefined;
    }


    const _HandleAdd = async() => {
        if(selected_atleta) {
            if(parseInt(search.staff) === 4) {
                let _atletas = [...list];
                let index = _atletas.findIndex(s => s.atleta_id === parseInt(selected_atleta.id));

                if(index > -1) {
                    window.swal("Alerta", "Não é permitido ao staff assumir duas funçôes", "error");
                    return;
                }

                if(selected_atleta.ficha_cl_visual === 0) {
                    selected_atleta.ficha_cl_visual = (Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade?.toLowerCase().indexOf("judô") > -1) ? 4 : 1;
                }


                if(selected_atleta.ficha_categoria === 0) selected_atleta.ficha_categoria = 1;
                if(selected_atleta.ficha_peso === 0 && (Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade?.toLowerCase().indexOf("judô") > -1) ) selected_atleta.ficha_peso = Modalidade?.toLowerCase().indexOf("feminino") > -1 ? 48 : 60;
                if(selected_atleta.ficha_faixa === 0 &&  (Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade?.toLowerCase().indexOf("judô") > -1) ) selected_atleta.ficha_faixa = 1;
                if(selected_atleta.ficha_funcao === 0 &&  (Modalidade?.toLowerCase().indexOf("futebol") > -1) ) selected_atleta.ficha_funcao = 1;

                
                await Req("api/Inscricao/AddAtleta", "POST", Object.assign(selected_atleta, {ficha_id: ficha?.ficha_id, entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}), user.token, 0).then(data => {
                    valorEvento = data; 
                    setList([...list, selected_atleta]);
                    window.swal("Sucesso", "Atleta adicionado com sucesso", "success");
                });
                
                
            } else {
                let _staff = [...listStaff];
                let index = _staff?.findIndex(s => s.atleta_id === parseInt(selected_atleta.id));


                if(index > -1) {
                    window.swal("Alerta", "Não é permitido ao staff assumir duas funçôes", "error");
                    return;
                }

                let _index = _staff?.findIndex(s => parseInt(s.staff) === parseInt(search.staff));
                if(_index > -1) {
                    _staff[_index].atleta_id = selected_atleta.id;
                    _staff[_index].nome = selected_atleta.name;

                }
                
                await Req("api/Inscricao/AddStaff", "POST", {..._staff[_index], ficha_id: ficha?.ficha_id, entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}, user.token, 0).then(data => {
                    //_staff[_index].pay = (_index === 0 ? data.tecnico_pay: (_index === 1 ? data.auxiliar_pay : data.acompanhante_pay));
                    valorEvento = data;   
                    setStaffList(_staff);
                    window.swal("Sucesso", "Staff adicionado com sucesso", "success"); 
                }); 

                
            }
            selected_atleta = undefined;
            window.$("#word").attr("placeholder", "");
            setAtletas([]);
            setAtleta(undefined);
            setSearch(prev => prev = {...prev, word: ""});
        }
    }


    const _HandleDelete = async(item, i) => {
        let _list = [...list];
        _list.splice(i, 1);
        setList(_list);
        await Req("api/Inscricao/RemoveAtleta", "POST", {...item, ficha_id: ficha?.ficha_id, entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}, user.token, 0);
    }


    const _HandleStaffDelete = async(item, i) => {
        let _staff = [...listStaff];
        _staff[i].atleta_id = "";
        _staff[i].nome = "";
        setStaffList(_staff);
        await Req("api/Inscricao/RemoveStaff", "POST", {...item, ficha_id: ficha?.ficha_id, entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}, user.token, 0);
    }


    const _HandleAtletaChange = async(event, item, i, field) => {
        let _list = [...list];
        _list[i][field] = event.target.value;
        setList(_list);
        await Req("api/Inscricao/UpdateAtleta", "POST", {id: item.id, [field]: event.target.value, ficha_id: ficha?.ficha_id, entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}, user.token, 0);
    }


    const _handleCloseModal = async() => {
        let _atleta = {...selAtleta};
        console.log(_atleta);
        if(!_atleta?.provas || _atleta?.provas.length === 0) {
            window.swal("Alerta", "É necessario escolher as provas para o atleta!", "warning");
        }


        if(anyChange) {
            await Req("api/Inscricao/UpdateAtletaProvas", "POST", {..._atleta, ficha_id: ficha?.ficha_id, entidade_id: EntidadeId, evento_id: EventoId, etapa_id: EtapaId, time_id: TimeId}, user.token, 0)
            .then(() => {
                window.swal("Sucesso", "Provas adicionadas com sucesso!", "warning");
            })
        }

        let _list = [...list];
        let _index = _list.findIndex(item => item.id === _atleta.id);
        if(_index > -1) {
            _list[_index].provas = _atleta?.provas;
            //console.log(_list);
            setList(_list);
        }
        setShowModal(false);
    }


    const _handleOpenModal = async(item) => {
        //console.log(item);
        setSelAtleta(item);
        setShowModal(true);
    }


    const _HandleProvaChange = (event, item) => {
        let _atleta = {...selAtleta};
        if(!_atleta?.provas) _atleta.provas = [];

        let _index = _atleta?.provas?.findIndex(a => parseInt(a.prova_id) === parseInt(item.prova_id));

        console.log(event.target.checked, item.prova_id, _index );

        if(event.target.checked) {
            if(_index === -1) {
                _atleta?.provas?.push({prova_id: item.prova_id, niveis: []});
            } else {
                //console.log(item);
                _atleta.provas[_index].prova_id = item.prova_id;
                //_atleta.provas[_index].niveis = item.niveis;
            }
        } else {
            if(_index > -1) {
                _atleta?.provas?.splice(_index, 1);
            }
        }
        
        //console.log(_atleta);
        setAnyChange(true);
        setSelAtleta(_atleta);
    }


    const _handleNivelChange = (event, item, nivel) => {
        let _atleta = {...selAtleta};
        let _index = _atleta?.provas?.findIndex(a => parseInt(a.prova_id) === parseInt(item.prova_id));

        let _niveis = _atleta.provas[_index]?.niveis.length > 0 ? _atleta.provas[_index]?.niveis?.split(",") : [];
        let _nindex = _niveis.findIndex(a => a === nivel);

    


        if(event.target.checked) {
            if(_nindex === -1) {
               _niveis.push(nivel);
            }
        } else {
            if(_nindex > -1) {
                _niveis.splice(_nindex, 1);
            }
        }

        _atleta.provas[_index].niveis = _niveis.join();
        //console.log(_atleta);

        setAnyChange(true);
        setSelAtleta(_atleta);
    }
    //#endregion Handlers




    return (
            <div className="Time">
                <div className="_d-flex _flex-wrap">
                    <div className="row"> 
                        {
                            EditableMode === true && ficha?.status === 0 && (
                                <div className="col-xl-12"> 
                                    <div className="align-right mr-10">
                                        <button type="button" className="btn btn-primary" onClick={_HandleAdd} aria-label="Adicionar Atleta ou Staff" tabIndex={3} aria-colindex={3}>
                                            <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                        </button>
                                    </div>

                                    <div className="align-right mr-10">
                                        <button type="button" className="btn btn-outline-primary" onClick={_HandleDatalistAll} aria-label="Atletas Elegíveis" tabIndex={2} aria-colindex={2}>
                                            <i className="uil uil-users-alt"></i> &nbsp;Atletas Elegíveis
                                        </button>
                                    </div>

                                    <div className="search-box align-right mr-10">
                                        <div className="position-relative">
                                            <select id="staff" className="form-select" value={search.staff} onChange={_HandleSearchChange} aria-label="Condição" tabIndex={1} aria-colindex={1}> 
                                                {
                                                    staff_list.length > 0 && staff_list?.map((item, i) => (
                                                        <option key={'staff'+i} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div> 

                                    <div className="align-right search-box mr-10">
                                        <div className="position-relative">
                                            <input id="word" name="word" typeName="text" className="form-control rounded bg-light border-0" value={search.word} list={'atletas_datalist'+TimeId} onclick="this.setSelectionRange(0, this.value.length)" placeholder="" onChange={_HandleDatalistChange} autoComplete="off" tabIndex={0}  aria-colindex={0} aria-label="Buscar por nome"/>
                                            <i className="bx bx-search search-icon"></i>

                                            <datalist id={'atletas_datalist'+TimeId} open="open">
                                            {
                                                    Array.isArray(atletas) && atletas.length > 0 && atletas.map((option, i) => (
                                                        <option key={'atleta_' + i} value={option.id + '. ' + option.name} selected={option.selected === true}>{option.cpf}</option>
                                                    ))
                                            }
                                            </datalist>
                                        </div>
                                    </div> 
                                </div>  
                            )
                        }  

                        {
                            (ficha?.status !== 0) && (
                                <div className="row">
                                    <center>
                                        <h4 style={{color:'#FF0000'}}>FICHA ENVIADA </h4>

                                    </center>
                                </div>  
                            )
                        }


                       

                        {/*
                             (!EditableMode && (
                                <div className="row">
                                    <center>
                                        <h4 style={{color:'#FF0000'}}>INSCRIÇÕES ENCERRADAS</h4>
                                    </center>
                                </div>  
                            ))
                        */}
                    </div>

                    
                </div>

                <br />

                <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                        <thead>
                            <tr className="th_staff">
                                <th colSpan={2}><h5>Ficha de Inscrição - Staff</h5></th>
                                { EditableMode === true && ficha?.status === 0 && (<th style={{width: '45px'}}></th>) }
                            </tr>
                        </thead>


                        <tbody>
                        
                        {listStaff.map((item, i) => (
                                <tr key={'tr' + i} className="pointer">
                                    <td style={{width: '220px'}}><b>{staff_list?.find(t => t.id === item.staff)?.name}</b></td>
                                    <td>{item.nome}</td>
                                    { EditableMode === true && ficha?.status === 0 && (<td>
                                        <i className="fa fa-trash" onClick={(e) => _HandleStaffDelete(item, i)}></i>
                                    </td>) }
                                </tr> 
                        ))}
                        
                        </tbody>
                    </table> 
                </div>


                <br />

                <div className="table-responsive">
                    <table className="table table-bordered mb-0 tb-aletas">
                        <thead>
                            <tr className="th_atletas">
                                <th><h5>Ficha de Inscrição - Atletas</h5></th>
                                {( (Modalidade?.toLowerCase().indexOf("atletismo") > -1 || Modalidade?.toLowerCase().indexOf("natação") > -1) ? (<></>) : (<th>Classe</th>))}
                                <th>Categoria</th>
                                {(Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade?.toLowerCase().indexOf("judô") > -1) && (<th>Peso</th>)}
                                {(Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade?.toLowerCase().indexOf("judô") > -1) && (<th>Faixa</th>)}
                                {(Modalidade?.toLowerCase().indexOf("futebol") > -1) && (<th>Função</th>)}
                                { (Modalidade?.toLowerCase().indexOf("atletismo") > -1 || Modalidade?.toLowerCase().indexOf("natação") > -1) && (<th style={{width: '55px'}}>Provas</th>)}
                                { EditableMode === true && ficha?.status === 0 && (<th style={{width: '45px'}}></th>) }
                            </tr>
                        </thead>


                        <tbody>
                        
                        {list.map((item, i) => (
                                <tr key={'tr' + i} className="pointer">
                                    <td style={{paddingLeft:'12px'}}>{item.name}</td>
                                    {(Modalidade?.toLowerCase().indexOf("atletismo") > -1 || Modalidade?.toLowerCase().indexOf("natação") > -1) ? (<></>) : (
                                    <td>
                                        <select id={'cl_visual'+i} className="form-select inputfull" value={item.ficha_cl_visual} onChange={(event) => _HandleAtletaChange(event, item, i, 'ficha_cl_visual')} disabled={!EditableMode || ficha?.status !== 0} aria-label="Classe">
                                            {
                                                (Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade?.toLowerCase().indexOf("judô") > -1) ? (
                                                    <>
                                                        <option value={4}>J1</option>
                                                        <option value={5}>J2</option>
                                                    </>
                                                ) : (

                                                    <>
                                                        <option value={1}>B1</option>
                                                        <option value={2}>B2</option>
                                                        <option value={3}>B3</option>      
                                                    </>
                                                )
                                            }
                                        </select>
                                    </td>)}
                                    <td>
                                        <select id={'categoria'+i} className="form-select inputfull" value={item.ficha_categoria} onChange={(event) => _HandleAtletaChange(event, item, i, 'ficha_categoria')} disabled={!EditableMode || ficha?.status !== 0} aria-label="Categoria">
                                            <option value={1}>Divisão Especial</option>
                                            <option value={2}>Aspirante</option>
                                        </select>
                                    </td>


                                    {(Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade.toLowerCase().indexOf("judô") > -1) && (<td>
                                        {
                                            
                                            Modalidade?.toLowerCase().indexOf("feminino") > -1 ? (<>
                                                <select id={'peso'+i} className="form-select inputfull" value={item.ficha_peso} onChange={(event) => _HandleAtletaChange(event, item, i, 'ficha_peso')} disabled={!EditableMode || ficha?.status !== 0} aria-label="Peso">
                                                    <option value={48}>Até 48 kg</option>
                                                    <option value={52}>48 a 52 kg</option>
                                                    <option value={57}>52 a 57 kg</option>
                                                    <option value={63}>57 a 63 kg</option>
                                                    <option value={70}>63 a 70 kg</option>
                                                    <option value={78}>70 a 78 kg</option>
                                                    <option value={79}>+ que 78 kg</option>
                                                </select>
                                            </>) : (<>
                                                <select id={'peso'+i} className="form-select inputfull" value={item.ficha_peso} onChange={(event) => _HandleAtletaChange(event, item, i, 'ficha_peso')} disabled={!EditableMode || ficha?.status !== 0} aria-label="Peso">
                                                    <option value={60}>Até 60 kg</option>
                                                    <option value={66}>60 a 66 kg</option>
                                                    <option value={73}>66 a 73 kg</option>
                                                    <option value={81}>73 a 81 kg</option>
                                                    <option value={90}>81 a 90 kg</option>
                                                    <option value={100}>90 a 100 kg</option>
                                                    <option value={101}>+ que 100 kg</option>
                                                </select>  
                                            </>) 
                                        }
                                    </td>)}

                                    {(Modalidade?.toLowerCase().indexOf("judo") > -1 || Modalidade.toLowerCase().indexOf("judô") > -1) && (<td>
                                        <select id={'faixa'+i} className="form-select inputfull" value={item.ficha_faixa} onChange={(event) => _HandleAtletaChange(event, item, i, 'ficha_faixa')} disabled={!EditableMode || ficha?.status !== 0} aria-label="Faixa">
                                            <option value={1}>Branca</option>
                                            <option value={2}>Azul</option>
                                            <option value={3}>Amarela</option>
                                            <option value={4}>Laranja</option>
                                            <option value={5}>Verde</option>
                                            <option value={6}>Marrom</option>
                                            <option value={7}>Preta</option>
                                        </select>  
                                    </td>)}   


                                    {(Modalidade?.toLowerCase().indexOf("futebol") > -1) && (<td>
                                        <select id={'funcao'+i} className="form-select inputfull" value={item.ficha_funcao} onChange={(event) => _HandleAtletaChange(event, item, i, 'ficha_funcao')} disabled={!EditableMode || ficha?.status !== 0} aria-label="Função">
                                            <option value={1}>Jogador</option>
                                            <option value={2}>Goleiro</option>
                                        </select> 
                                    </td>)}  


                                    { (Modalidade?.toLowerCase().indexOf("atletismo") > -1 || Modalidade?.toLowerCase().indexOf("natação") > -1) && (<td>
                                        <center>
                                        <i className="mdi mdi-weight-lifter mdi-24px" onClick={() => _handleOpenModal(item)}></i>
                                        </center>
                                    </td>) }
                                    { EditableMode === true && ficha?.status === 0 && (<td style={{paddingLeft:'12px'}}>
                                        <i className="fa fa-trash" onClick={(e) => _HandleDelete( item, i)}></i>
                                    </td>) }
                                </tr> 
                        ))}
                        
                        </tbody>
                    </table>          
                </div>

                <br />

                {
                    (ficha?.status === 0) && (
                        <div className="row">
                            <center>
                                <h4 style={{color:'#FF0000'}}>FICHA NÃO ENVIADA </h4>
                            </center>
                        </div>  
                    )
                }


                <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
                    <MDBModalDialog  size='xl'>
                        <MDBModalContent >
                            <MDBModalHeader>
                                <MDBModalTitle><h4>Provas para {Modalidade}</h4></MDBModalTitle>
                                <Button className='btn btn-primary' onClick={_handleCloseModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <table className="table mb-0 table-modal">
                                    <thead>
                                        <tr>
                                            <th style={{width:'35px'}}></th>
                                            <th>Prova</th>
                                            <th>Classe</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            Array.isArray(Provas) && Provas.length > 0 && Array.isArray(provas) && provas.length > 0 && Provas.map((item, i) => (
                                                <tr key={'prova'+i}>
                                                    <td>
                                                        <input id={"titulo_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.prova_id} checked={selAtleta?.provas?.findIndex(t => t.prova_id === item.prova_id) > -1} onClick={(event) => _HandleProvaChange(event, item)} disabled={(EditableMode === false || ficha?.status !== 0)}/> 
                                                    </td>
                                                    <td>
                                                        {provas.find(t => t.prova_id === item.prova_id)?.nome} <b>{provas.find(t => t.prova_id === item.prova_id)?.estilo}</b>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.niveis!=='' && item.niveis.split(',').map((nivel, n) => (
                                                                <React.Fragment key={'nivel'+i+'_'+n}>
                                                                    <input id={"nivel_chk"+i+'_'+n} type="checkbox" style={{width:'22px', height: '22px'}} value={nivel} checked={selAtleta?.provas?.find(t => t.prova_id === item.prova_id)?.niveis.length > 0 && selAtleta?.provas?.find(t => t.prova_id === item.prova_id)?.niveis?.split(",").findIndex(n => n === nivel) > -1} onClick={(event) => _handleNivelChange(event, item, nivel)} disabled={selAtleta?.provas?.findIndex(t => t.prova_id === item.prova_id) === -1}/> <label className="inline"> {nivel} </label>
                                                                </React.Fragment>
                                                            ))

                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }   
                                    </tbody>    
                                 </table>
                            </MDBModalBody>

                            <MDBModalFooter>
                                    <Button variant="secondary" onClick={_handleCloseModal} >
                                    Fechar
                                    </Button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </div>
    )
}